import {
    AccountConfiguration,
    Command,
    Entrypoint,
    Event,
    Id,
    IndexedItem,
    Lookup,
    NamedGeoLocation,
    Scheduled,
    ScheduledId
} from "./domain/data";
import {ISO8601_DateTime} from "./datetime";

export type AccountId = string & { readonly __tag_AccountId: unique symbol };
export type SessionId = string & { readonly __tag_SessionId: unique symbol };
export type ClientVersion = string & { readonly __tag_ClientVersion: unique symbol };
export type EventId = number & { readonly __tag_EventId: unique symbol };
export type CmdIdentifier = string & { readonly __tag_CmdIdentifier: unique symbol };

export const ERROR_CODE_BAD_LOGIN = 4000;
export const ERROR_CODE_BAD_CLIENT_VERSION = 4001;

export type WSClientState
    = {type: "NoState"}
    | {type: "NoEventObserved"}
    | {type: "LastObservedEvent", id: EventId}
    ;

export type WSUserSuppliedSession = {
    type: "WSUserSuppliedSession",
    clientVersion: ClientVersion,
    sessionId: SessionId,
    clientState: WSClientState,
};
export type WSDomainCommands = {
    type: "WSDomainCommands",
    id: CmdIdentifier,
    commands: readonly Command[],
};
export type WSClientToServer
    = WSUserSuppliedSession
    | WSDomainCommands
    ;

export type WSAwaitingCommand = {
    type: "WSAwaitingCommand",
};
export type WSState = {
    type: "WSState",
    root: Entrypoint<Id>,
    items: IndexedItem<Id, Id>[],
    geoLocations: NamedGeoLocation[],
    schedule: Scheduled<ScheduledId, readonly Lookup<Id>[]>[],
    config: AccountConfiguration,
    clientState: WSClientState,
};
export type WSEvent = {
    type: "WSEvent",
    id: EventId,
    time: ISO8601_DateTime,
    event: Event<Id, Id>,
};
export type WSEventForUi = {
    type: "WSEventForUi",
    id: EventId,
    time: ISO8601_DateTime,
    event: Event<Id, Id>,
};
export type WSEventId = {
    type: "WSEventId",
    id: EventId,
};
export type WSCommandAck = {
    type: "WSCommandAck",
    id: CmdIdentifier,
};
export type WSDesync = {
    type: "WSDesync",
    id: CmdIdentifier,
};
export type WSServerToClient
    = WSAwaitingCommand
    | WSState
    | WSEvent
    | WSEventForUi
    | WSEventId
    | WSCommandAck
    | WSDesync
    ;

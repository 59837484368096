import {BootstrapWindowSize, ItemGraph, Model,} from "../../types";
import {SchedulingPath} from "../../../common/urlpath";
import React, {ReactElement, useState} from "react";
import {Button, Card, Form} from "react-bootstrap";
import {
    Id,
    Lookup,
    Recurrence,
    RecurrenceEveryMonthOnNthDayName,
    RecurrenceEveryMonthOnNthDayNumber,
    RecurrenceEveryNDays,
    RecurrenceEveryWeek,
    RecurrenceEveryYear,
    RecurrenceOnce, ROOT,
    Scheduled,
    ScheduledId,
    Title
} from "../../../common/domain/data";
import {isNotNull, isSomething, mapNull} from "../../../common/util";
import {domainTx, handleCommands} from "../../ui-state";
import {
    addDuration,
    DayName,
    DayNumber,
    ISO8601_Date,
    ISO8601_DateWithoutYear,
    MonthNumber,
    now,
    toIsoDateString
} from "../../../common/datetime";
import {mutable} from "../../mutable";
import {numericalSuffix} from "../../../common/domain/scheduling";
import {uuidv4} from "../../../common/uuid";
import {ConfirmationRequiredButton} from "../../components/ConfirmationRequiredButton";
import {ItemTitle} from "../../components/ItemTitle";

export function schedulingView(model: Model, _path: SchedulingPath): ReactElement {
    const scheduled = mapNull(model.scheduling.editing, editing => model.scheduling.schedule[editing]);
    if (scheduled) {
        return <Card key={"edit"}>
            <Card.Body>
                <ScheduleForm
                    scheduled={scheduled}
                    submitText={"Edit"}
                    showCancel={true}
                    onSubmit={scheduled => {
                        void domainTx(tx => handleCommands(tx, [{
                            type: "EditScheduled",
                            scheduled,
                        }])).then(() => {
                            mutable.send({type: "EditScheduled", id: null});
                        });
                    }}
                />
            </Card.Body>
        </Card>;
    }

    return <Card key={"new"}>
        <Card.Body>
            <ScheduleForm
                scheduled={null}
                submitText={"Submit"}
                onSubmit={scheduled => {
                    void domainTx(tx => handleCommands(tx, [{
                        type: "AddScheduled",
                        scheduled,
                    }]));
                }}
            />
        </Card.Body>
        <Card.Body>
            <Form key={"existing"}>
                <table>
                    <thead>
                    <tr key={"head"}>
                        <th>Parents</th>
                        <th>Title</th>
                        <th>Recurrence</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.values(model.scheduling.schedule)
                        .map((s, i) =>
                            <tr key={i}>
                                <td>{s.parents.map(p => mutable.search.get(p)?.noteData.title).join(", ")}</td>
                                <td>{s.title}</td>
                                <td>{s.recurrence.map(prettyRecurrence).join(", ")}</td>
                                <td width={model.windowSize < BootstrapWindowSize.Medium ? "100%" : "10%"}>
                                    <Button
                                        variant={"outline-primary"}
                                        size={"sm"} style={{marginTop: "1em"}}
                                        onClick={ev => {
                                            ev.preventDefault();
                                            mutable.send({type: "EditScheduled", id: s.id})
                                        }}
                                    >✎</Button>
                                    <ConfirmationRequiredButton
                                        detail={"Are you sure you want to delete this schedule?"}
                                        size={"sm"} style={{marginTop: "1em", marginLeft: "0.5em"}}
                                        variant={"outline-danger"}
                                        onConfirm={ev => {
                                            ev.preventDefault();
                                            void domainTx(tx => handleCommands(tx, [{
                                                type: "RemoveScheduled",
                                                id: s.id,
                                            }]));
                                        }}
                                    >×</ConfirmationRequiredButton>
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </table>
            </Form>
        </Card.Body>
    </Card>;
}

type ScheduleFormProps = {
    scheduled: null | Scheduled<ScheduledId, readonly Lookup<Id>[]>
    submitText: string,
    showCancel?: boolean,
    onSubmit: (s: Scheduled<ScheduledId, readonly Lookup<Id>[]>) => void,
};

function ScheduleForm({scheduled, submitText, showCancel = false, onSubmit}: ScheduleFormProps): ReactElement {
    const [parents, setParents] = useState<readonly (null | ItemGraph)[]>(
        scheduled?.parents.map(p => mutable.search.get(p)).filter(isSomething)
        ?? [null]);
    const [title, setTitle] = useState(scheduled?.title ?? "" as Title);
    const [recurrence, setRecurrence] = useState(scheduled?.recurrence ?? []);
    const someDay = addDuration(now(), "d", 100);

    return <Form
        key={"schedule"}
        onSubmit={ev => {
            ev.preventDefault();
            if (!(ev.target as HTMLFormElement).checkValidity()) return;

            if (parents.length > 0 && recurrence.length > 0) {
                onSubmit({
                    id: scheduled?.id || uuidv4(),
                    parents: parents
                        .filter(isNotNull)
                        .map(isr => isr.id),
                    title,
                    recurrence,
                });

                setParents([mutable.search.get(ROOT)]);
                setTitle("" as Title);
                setRecurrence([]);
            }
        }}
    >
        {(() => {
            if (!scheduled?.id) return <></>;
            return <input type={"hidden"} name={"id"} value={scheduled?.id} />
        })()}

        <table style={{borderCollapse: "separate", borderSpacing: "0 0.3em", width: "100%", verticalAlign: "middle"}}>
            <tbody>
            {parents.map((p, i) =>
                <tr key={`parent-${i}`}>
                    <td>
                        <Form.Group>
                            <ItemTitle
                                key={`${i}-${p === null ? "empty" : "full"}`}
                                initialState={p === null
                                    ? {
                                        defaultHighlight: 0,
                                    }
                                    : {
                                        defaultHighlight: 0,
                                        input: p.noteData.title,
                                        chosenItem: p,
                                    }
                                }
                                componentStyle={{width: "100%"}}
                                placeholder={`parent ${i + 1}`}
                                required
                                idBlockList={parents.filter(isNotNull).map(r => r.id as Lookup<Id>)}
                                onItemChosen={chosen => {
                                    if (chosen === null) return;

                                    const copy = [...parents];
                                    copy[i] = chosen;
                                    setParents(copy);
                                }}
                            />
                        </Form.Group>
                    </td>
                    <td width={"10em"}>
                        <Button disabled={parents.length <= 1} variant={"outline-danger"} size={"sm"} onClick={() => {
                            const copy = [...parents];
                            copy.splice(i, 1);
                            setParents(copy);
                        }}>×</Button>
                    </td>
                </tr>)}
            </tbody>
        </table>
        <Form.Group key={"add-parent"}>
            <Button size={"sm"} onClick={() => {
                const copy = [...parents, mutable.search.get(ROOT)];
                setParents(copy);
            }}>Add another parent</Button>
        </Form.Group>
        <Form.Group key={"title"} style={{marginTop: "1em"}}>
            <Form.Label>Title</Form.Label>
            <Form.Control name={"item-title"} type={"text"} placeholder={"title"} pattern={"-{3,}|.*\\w.*"} title={"Provide a non-empty title"} value={title} required onChange={ev => {
                setTitle(ev.target.value as Title);
            }} />
        </Form.Group>

        <Form.Group key={`recurrence-type`} style={{marginTop: "1em"}}>
            <Form.Label>Recurrence examples</Form.Label>
            <FormSelect<"" | Recurrence["type"]>
                name={"recurrence-type"}
                items={[
                    ["", `${recurrence.length === 0 ? "Required: Choose a" : "Optional: Add another"} recurrence`],
                    ["once", `Once on ${someDay.toLocaleDateString()}`],
                    ["every-n-days", `Every 3 days starting on ${addDuration(now(), "d", 1).toLocaleDateString()}`],
                    ["every-week", "Every week on Wednesday"],
                    ["every-month-on-nth-day-number", "Every month on the 27th"],
                    ["every-month-on-nth-day-name", "Every month on the third Tuesday"],
                    ["every-year", `Every year on the ${someDay.getDate()}${numericalSuffix(someDay.getDate())} of ${monthName(someDay.getMonth())}`],
                ]}
                onChange={(type) => {
                    if (type === "") return true;
                    setRecurrence(recurrence => [...recurrence, defaultRecurrence(type)]);
                    return false;
                }}
            />
            {recurrence.length === 0 ? <Form.Text className={"text-muted"}>No recurrence set</Form.Text> : undefined}
        </Form.Group>

        <table style={{borderCollapse: "separate", borderSpacing: "0 0.3em"}}>
            <tbody>
            {recurrence.map((r, i) => <tr key={`recurrence-${i}`}>
                <td>
                    <Button variant={"outline-danger"} size={"sm"} onClick={() => {
                        const copy = [...recurrence];
                        copy.splice(i, 1);
                        setRecurrence(copy);
                    }}>×</Button>
                </td>
                <td>
                    <RecurrenceEdit key={i} index={i} recurrence={r} onValid={r => {
                        const copy = [...recurrence];
                        copy[i] = r;
                        setRecurrence(copy);
                    }}/>
                </td>
            </tr>)}
            </tbody>
        </table>

        {(() => {
            if (!showCancel) return;

            return <Button variant={"danger"} type={"submit"} style={{marginTop: "1em", marginRight: "0.5em"}} onClick={ev => {
                ev.preventDefault();
                mutable.send({type: "EditScheduled", id: null})
            }}>Cancel</Button>
        })()}

        <Button variant={"primary"} type={"submit"} style={{marginTop: "1em"}}>{submitText}</Button>
    </Form>;
}

type RecurrenceEditProps = {
    index: number,
    recurrence: Recurrence,
    onValid: (recurrence: Recurrence) => void,
};
function RecurrenceEdit({index, recurrence, onValid}: RecurrenceEditProps): ReactElement {
    switch (recurrence.type) {
        case "once":
            return <RecurrenceOnceEdit index={index} recurrence={recurrence} onValid={onValid}/>;

        case "every-n-days":
            return <RecurrenceEveryNDaysEdit index={index} recurrence={recurrence} onValid={onValid}/>;

        case "every-week":
            return <RecurrenceEveryWeekEdit index={index} recurrence={recurrence} onValid={onValid}/>;

        case "every-month-on-nth-day-number":
            return <RecurrenceEveryMonthOnNthDayNumberEdit index={index} recurrence={recurrence} onValid={onValid}/>;

        case "every-month-on-nth-day-name":
            return <RecurrenceEveryMonthOnNthDayNameEdit index={index} recurrence={recurrence} onValid={onValid}/>;

        case "every-year":
            return <RecurrenceEveryYearEdit index={index} recurrence={recurrence} onValid={onValid}/>;

        default: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const _: never = recurrence;
            // noinspection ExceptionCaughtLocallyJS
            throw new Error(`Unexpected object: ${JSON.stringify(recurrence)}`);
        }
    }
}

type RecurrenceEditSpecificProps<T extends Recurrence> = {
    index: number,
    recurrence: T,
    onValid: (recurrence: T) => void,
}

function RecurrenceOnceEdit({index, recurrence, onValid}: RecurrenceEditSpecificProps<RecurrenceOnce>): ReactElement {
    const [date, setDate] = useState<"" | ISO8601_Date>(recurrence.date);

    return <Form.Group><table><tbody><tr>
        <td>Once on</td>
        <td>
            <Form.Control name={`recurrence-${index}-date`} type={"date"} min={tomorrow()} required value={date} onChange={ev => {
                const date = ev.target.value as ISO8601_Date;
                setDate(date);
                if (date !== "") {
                    onValid({type: "once", date});
                }
            }}/>
        </td></tr>

        {(() => {
            if (date !== "") return undefined;

            return <tr>
                <td>or in</td>
                <td><Form.Control name={`recurrence-${index}-days-from-today`} type={"number"} min={0} step={1} onBlur={ev => {
                    const date = toIsoDateString(addDuration(new Date(), "d", parseInt(ev.target.value)));
                    setDate(date);
                    if (date !== "") {
                        onValid({type: "once", date});
                    }
                }}/></td>
                <td>days</td>
            </tr>;
        })()}
    </tbody></table></Form.Group>;
}

function RecurrenceEveryNDaysEdit({index, recurrence, onValid}: RecurrenceEditSpecificProps<RecurrenceEveryNDays>): ReactElement {
    const [n, setN] = useState<"" | number>(recurrence.n);
    const [ref, setRef] = useState<"" | ISO8601_Date>(recurrence.ref);

    return <Form.Group><table><tbody><tr>
        <td>Every</td>
        <td><Form.Control name={`recurrence-${index}-n`} type={"number"} min={1} step={1} style={{width: "4em"}} required value={n} onChange={ev => {
            const n = parseInt(ev.target.value);
            setN(n);
            if (n && ref) {
                onValid({type: "every-n-days", n, ref});
            }
        }}/></td>
        <td>days starting on</td>
        <td><Form.Control name={`recurrence-${index}-ref`} type={"date"} min={tomorrow()} required value={ref} onChange={ev => {
            const ref = ev.target.value as ISO8601_Date;
            setRef(ref);
            if (n && ref) {
                onValid({type: "every-n-days", n, ref});
            }
        }}/></td>
    </tr></tbody></table></Form.Group>;
}

function RecurrenceEveryWeekEdit({index, recurrence, onValid}: RecurrenceEditSpecificProps<RecurrenceEveryWeek>): ReactElement {
    const [day, setDay] = useState<"" | DayName>(recurrence.day);

    return <Form.Group><table><tbody><tr>
        <td>Every week on</td>
        <td><FormSelect<DayName>
            name={`recurrence-${index}-dayName`}
            items={[
                ["mon", "Monday"],
                ["tue", "Tuesday"],
                ["wed", "Wednesday"],
                ["thu", "Thursday"],
                ["fri", "Friday"],
                ["sat", "Saturday"],
                ["sun", "Sunday"],
            ]}
            selected={day}
            required
            onChange={day => {
                setDay(day);
                if (day) {
                    onValid({type: "every-week", day});
                }

                return true;
            }}
        /></td>
    </tr></tbody></table></Form.Group>;
}

function RecurrenceEveryMonthOnNthDayNumberEdit({index, recurrence, onValid}: RecurrenceEditSpecificProps<RecurrenceEveryMonthOnNthDayNumber>): ReactElement {
    const [day, setDay] = useState<"" | DayNumber>(recurrence.day);

    return <Form.Group><table><tbody><tr>
        <td>Every month on the</td>
        <td>
            <Form.Control
                name={`recurrence-${index}-dayNumber`}
                type={"number"} min={1} step={1} max={31}
                required
                value={day}
                onChange={ev => {
                    const day = parseInt(ev.target.value) as DayNumber;
                    setDay(day);
                    if (day) {
                        onValid({type: "every-month-on-nth-day-number", day});
                    }
                }}/>
        </td>
        <td>{day === "" ? "" : numericalSuffix(day)}</td>
        <td style={{color: "slategrey"}}>{day === "" ? "" : monthDaysWarning(day)}</td>
    </tr></tbody></table></Form.Group>;
}

function RecurrenceEveryMonthOnNthDayNameEdit({index, recurrence, onValid}: RecurrenceEditSpecificProps<RecurrenceEveryMonthOnNthDayName>): ReactElement {
    const [week, setWeek] = useState<"" | number | "last">(recurrence.week);
    const [day, setDay] = useState<"" | DayName>(recurrence.day);

    const numbers: [string, string][] = [1, 2, 3, 4, "last" as const].map(n => [n.toString(), adjectiveTerm(n)]);

    return <>
        <Form.Group><table><tbody><tr>
            <td>Every month on the</td>
            <td><FormSelect<string>
                name={`recurrence-${index}-week`}
                items={numbers}
                selected={week.toString()}
                required
                value={week}
                onChange={weekStr => {
                    const week = weekStr === "last" ? "last" : parseInt(weekStr);
                    setWeek(week);
                    if (week && day) {
                        onValid({type: "every-month-on-nth-day-name", week, day});
                    }

                    return true;
                }}
            /></td>
            <td><FormSelect<DayName>
                name={`recurrence-${index}-dayName`}
                items={[
                    ["mon", "Monday"],
                    ["tue", "Tuesday"],
                    ["wed", "Wednesday"],
                    ["thu", "Thursday"],
                    ["fri", "Friday"],
                    ["sat", "Saturday"],
                    ["sun", "Sunday"],
                ]}
                selected={day}
                required
                onChange={day => {
                    setDay(day);
                    if (week && day) {
                        onValid({type: "every-month-on-nth-day-name", week, day});
                    }

                    return true;
                }}
            /></td>
        </tr></tbody></table></Form.Group>
    </>;
}

function RecurrenceEveryYearEdit({index, recurrence, onValid}: RecurrenceEditSpecificProps<RecurrenceEveryYear>): ReactElement {
    const [month, setMonth] = useState<string>(recurrence.date.replace(/-.*/, ""));
    const [day, setDay] = useState<string>(recurrence.date.replace(/.*-/, ""));

    return <Form.Group><table><tbody><tr>
        <td>Every year on</td>
        <td>
            <FormSelect<string>
                name={`recurrence-${index}-month`}
                items={[
                    ["01", "January"],
                    ["02", "February"],
                    ["03", "March"],
                    ["04", "April"],
                    ["05", "May"],
                    ["06", "June"],
                    ["07", "July"],
                    ["08", "August"],
                    ["09", "September"],
                    ["10", "October"],
                    ["11", "November"],
                    ["12", "December"],
                ]}
                selected={month}
                onChange={month => {
                    setMonth(month);
                    if (day && month) {
                        onValid({type: "every-year", date: `${month}-${day}` as ISO8601_DateWithoutYear});
                    }

                    return true;
                }}
            />
        </td>
        <td>
            <Form.Control
                name={`recurrence-${index}-day`}
                type={"number"} min={1} step={1} max={31}
                required
                value={day}
                onChange={ev => {
                    const day = ev.target.value.padStart(2, "0");
                    setDay(day);
                    if (day && month) {
                        onValid({type: "every-year", date: `${month}-${day}` as ISO8601_DateWithoutYear});
                    }
                }}/>
        </td>
    </tr></tbody></table></Form.Group>;
}

type FormSelectProps<T> = {
    items: [T, string][],
    selected?: "" | T,
    onChange?: (v: T, ev: React.ChangeEvent<HTMLSelectElement>) => boolean,
    [key: string]: any,
};
function FormSelect<T extends string>({items, selected, onChange = () => true, ...props}: FormSelectProps<T>): ReactElement {
    let defaultIndex = -1;
    if (selected) {
        defaultIndex = items.findIndex(([value, _text]) => value === selected);
        if (defaultIndex == -1) {
            throw new Error(`Selected item ${selected} is not a valid option`);
        }
    }

    if (defaultIndex === -1) {
        defaultIndex = items.findIndex(([value, _text]) => value === "");
    }

    if (defaultIndex === -1) {
        defaultIndex = 0;
    }

    return <Form.Select {...props} value={selected} onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => {
        if (!onChange(ev.target.value as T, ev)) {
            ev.target.selectedIndex = defaultIndex;
        }
    }}>
        {items.map(([value, pretty]) => <option value={value} key={value}>{pretty}</option>)}
    </Form.Select>
}

function prettyRecurrence(recurrence: Recurrence): string {
    switch (recurrence.type) {
        case "once":
            return `Once on ${new Date(recurrence.date).toLocaleDateString()}`;
        case "every-n-days":
            return `Every ${recurrence.n === 1 ? "" : `${recurrence.n} `}day${recurrence.n > 1 ? "s" : ""} starting on ${new Date(recurrence.ref).toLocaleDateString()}`;
        case "every-week":
            return `Every week on ${prettyDay(recurrence.day)}`;
        case "every-month-on-nth-day-number":
            return `Every month on the ${recurrence.day}${numericalSuffix(recurrence.day)}`;
        case "every-month-on-nth-day-name":
            return `Every month on the ${adjectiveTerm(recurrence.week)} ${prettyDay(recurrence.day)}`;
        case "every-year":
            return `Every year on ${prettyDateWithoutYear(recurrence.date)}`;
        default: {
            const _: never = recurrence;
            // noinspection ExceptionCaughtLocallyJS
            throw new Error(`Unexpected object: ${JSON.stringify(recurrence)}`);
        }
    }

}

function prettyDateWithoutYear(date: ISO8601_DateWithoutYear) {
    const month = parseInt(date.replace(/-.*/, ""));
    const day = parseInt(date.replace(/.*-/, ""));
    return `${prettyMonth(month as MonthNumber)} the ${day}${numericalSuffix(day)}`;
}

function prettyMonth(month: MonthNumber): string {
    return monthName(month - 1);
}

function prettyDay(day: DayName): string {
    switch (day) {
        case "mon": return "Monday";
        case "tue": return "Tuesday";
        case "wed": return "Wednesday";
        case "thu": return "Thursday";
        case "fri": return "Friday";
        case "sat": return "Saturday";
    }

    return "Sunday";
}

function monthName(zeroIndexedMonth: number): string {
    switch (zeroIndexedMonth) {
        case  0: return "January";
        case  1: return "February";
        case  2: return "March";
        case  3: return "April";
        case  4: return "May";
        case  5: return "June";
        case  6: return "July";
        case  7: return "August";
        case  8: return "September";
        case  9: return "October";
        case 10: return "November";
    }

    return "December";
}

function monthDaysWarning(date: DayNumber): string {
    switch (date) {
        case 29:
            return "(Probably excludes February)";
        case 30:
            return "(Excludes February)";
        case 31:
            return "(Excludes February, April, June, September, November)";
        default:
            return "";
    }
}

function defaultRecurrence(type: Recurrence["type"]): Recurrence {
    switch (type) {
        case "once":
            return {type, date: "" as ISO8601_Date};
        case "every-n-days":
            return {type, n: "" as unknown as number, ref: "" as ISO8601_Date};
        case "every-week":
            return {type, day: "" as DayName};
        case "every-month-on-nth-day-number":
            return {type, day: "" as unknown as DayNumber};
        case "every-month-on-nth-day-name":
            return {type, week: "" as unknown as number, day: "" as DayName};
        case "every-year":
            return {type, date: "" as ISO8601_DateWithoutYear};
        default: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const _: never = type;
            // noinspection ExceptionCaughtLocallyJS
            throw new Error(`Unexpected object: ${JSON.stringify(type)}`);
        }
    }
}

function adjectiveTerm(week: number | "last"): string {
    switch (week) {
        case 1: return "first";
        case 2: return "second";
        case 3: return "third";
        case 4: return "fourth";
        default: return week.toString();
    }
}

function tomorrow(): ISO8601_Date {
    // NB. can't execute this as a global constant because the day might change while the app runs!
    return toIsoDateString(addDuration(now(), "d", 1));
}
import {SessionId, WSClientState, WSDomainCommands} from "../common/protocol";
import {maybeNull} from "../common/util";
import {SearchIndex} from "./search";
import {GeolocationStub} from "./geo";

type LSData = {
    "uncaught": {
        "serializer": Serializer<string[]>,
        "deserializer": Deserializer<string[]>,
    },
    "clientState": {
        "serializer": Serializer<WSClientState>,
        "deserializer": Deserializer<WSClientState>,
    },
    "queuedCommandGroups": {
        "serializer": Serializer<WSDomainCommands[]>,
        "deserializer": Deserializer<WSDomainCommands[]>,
    },
    "searchIndex": {
        "serializer": Serializer<SearchIndex>,
        "deserializer": Deserializer<SearchIndex>,
    },
    "session": {
        "serializer": Serializer<SessionId>,
        "deserializer": Deserializer<SessionId | null>,
    },
    "_geolocationStub": {
        "serializer": Serializer<GeolocationStub>,
        "deserializer": Deserializer<GeolocationStub | null>,
    },
};

const serialization: LSData = {
    "uncaught": {
        "serializer": JSON.stringify,
        "deserializer": o => maybeNull(o, [], JSON.parse),
    },
    "clientState": {
        "serializer": JSON.stringify,
        "deserializer": o => maybeNull(o, {type: "NoState"}, JSON.parse),
    },
    "queuedCommandGroups": {
        "serializer": JSON.stringify,
        "deserializer": o => maybeNull(o, [], JSON.parse)
    },
    "session": {
        "serializer": o => o,
        "deserializer": o => o as SessionId | null,
    },
    "searchIndex": {
        "serializer": JSON.stringify,
        "deserializer": o => maybeNull(o, {}, JSON.parse),
    },
    "_geolocationStub": {
        "serializer": JSON.stringify,
        "deserializer": o => maybeNull(o, null, JSON.parse),
    },
};

type Serializer<T> = (o: T) => string;
type Deserializer<T> = (o: string | null) => T;

export class Store {
    static setItem<Name extends keyof LSData>(name: Name, o: Parameters<LSData[Name]["serializer"]>[0]): void {
        const serializer: (o: any) => string = serialization[name].serializer;
        localStorage.setItem(name, serializer(o));
    }
    static getItem<Name extends keyof LSData>(name: Name): ReturnType<LSData[Name]["deserializer"]> {
        return serialization[name].deserializer(localStorage.getItem(name)) as ReturnType<LSData[Name]["deserializer"]>;
    }

    static removeItem<Name extends keyof LSData>(name: Name): void {
        localStorage.removeItem(name);
    }
}

import {ReactElement} from "react";
import {ListGroup, ListGroupItem} from "react-bootstrap";
import React from "react";
import {DebugComponent, DebugPath, debugToUiRepr, toUrlRepr} from "../../../common/urlpath";
import {Model} from "../../types";

export function debugView(model: Model, path: DebugPath): ReactElement {
    const debugComponent = path.component;
    switch (debugComponent) {
        case undefined:
            return (
                <ListGroup key={"debug"} className={"link-list"}>
                    <ListGroupItem>{debugPathLink("synchronous-error")}</ListGroupItem>
                    <ListGroupItem>{debugPathLink("asynchronous-error")}</ListGroupItem>
                </ListGroup>
            );

        case "synchronous-error":
            throw new Error(debugComponent);

        case "asynchronous-error":
            void Promise.reject(debugComponent);
            return <>debugComponent</>;

        default:
            const _: never = debugComponent;
            throw new Error(`Unexpected object: ${JSON.stringify(debugComponent)}`);
    }
}
export function debugPathLink(debugComponent: DebugComponent): ReactElement {
    return (
        <ListGroupItem key={`debug-${debugComponent}`}>
            <a key={"link"} href={`#${toUrlRepr({type: "Debug", component: debugComponent})}`}>
                {debugToUiRepr(debugComponent)}
            </a>
        </ListGroupItem>
    );
}

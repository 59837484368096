// noinspection NonAsciiCharacters

import {
    AccountConfiguration,
    AggregatedItemSubgraphData,
    Child,
    Command, Completable,
    DEFAULT_LOCATION,
    Detail,
    DoneState,
    Entrypoint,
    Event,
    Id,
    IndexedItem,
    Item,
    Location, Lookup,
    NamedGeoLocation, NoteData,
    Parent,
    RelativePosition,
    ROOT, Schedule,
    Scheduled,
    ScheduledId, ScheduleMutable,
    Title,
    TodoData
} from "./data";
import {isSomething, logStackTrace, mapUndef, maybeUndef, PromiseOrNot} from "../util";
import {
    ISO8601_DateTime,
    nowISO,
} from "../datetime";
import {findOverlapping, THRESHOLD_SAVING_METRES} from "./geolocation";

export interface ReadonlyDomainState<Tx> {
    getRoot(tx: Tx): Promise<Entrypoint<Id>>;

    getItem(tx: Tx, id: Id): Promise<IndexedItem<Id, Id> | undefined>;
    getItemThrows(tx: Tx, name: string, id: Id): Promise<IndexedItem<Id, Id>>;

    getAllItems(tx: Tx): Promise<IndexedItem<Id, Id>[]>;

    getConfig<K extends keyof AccountConfiguration>(tx: Tx, key: K): Promise<undefined | AccountConfiguration[K]>;
    getAllConfig(tx: Tx): Promise<Readonly<AccountConfiguration>>;

    getGeoLocations(tx: Tx): Promise<Readonly<{[key: string]: NamedGeoLocation}>>;

    getSchedule(tx: Tx): Promise<Schedule>;
}

export interface DomainState<Tx> extends ReadonlyDomainState<Tx> {
    writeRoot(tx: Tx, root: Entrypoint<Id>): Promise<void>;

    removeItems(tx: Tx, time: ISO8601_DateTime, ...ids: Id[]): Promise<{[key: Id]: IndexedItem<Id, Id>}>;
    writeItem(tx: Tx, item: IndexedItem<Id, Id>): Promise<void>;

    setConfig<K extends keyof AccountConfiguration>(tx: Tx, key: K, value: null | AccountConfiguration[K]): Promise<void>;
    getAllConfig(tx: Tx): Promise<Readonly<AccountConfiguration>>;

    addGeoLocation(tx: Tx, geoLocation: NamedGeoLocation): Promise<void>;
    removeGeoLocation(tx: Tx, name: string): Promise<void>;

    setScheduled(tx: Tx, scheduled: Scheduled<ScheduledId, readonly Lookup<Id>[]>): Promise<void>;
    removeScheduled(tx: Tx, id: ScheduledId): Promise<void>;

    abort(tx: Tx): Promise<void>;
}

type Removed = { [key: Id]: IndexedItem<Id, Id> };
// This function returns a collection of removed items,
// it does include the entrypoint items
// those entrypoint items are returned with no parents (so that it's easier to bulk-write them in the indexeddb case)
// it does not include parents of entrypoint items
// it does not write any changes to storage
export async function removeSubGraph(
    getRoot: () => PromiseOrNot<Entrypoint<Id>>,
    getItems: (ids: Id[]) => PromiseOrNot<IndexedItem<Id, Id>[]>,
    ...removedEntrypoints: Id[]): Promise<Removed> {
    const itemsRemovedFromGraph: Removed = {};

    const entrypointItems = (await getItems(removedEntrypoints))
        .map(i => ({...i, parents: []}));

    const parentsOfEntrypoints = new Set<Parent<Id>>();
    for (const entrypointItem of entrypointItems) {
        entrypointItem.parents
            .filter(p => p !== ROOT)
            .forEach(p => {
                parentsOfEntrypoints.add(p);
            });

        itemsRemovedFromGraph[entrypointItem.id] = {...entrypointItem, parents: []};
    }

    // traverse the subgraphs
    let frontier = new Set(entrypointItems.flatMap(i => i.children));
    while (frontier.size > 0) {
        const items = await getItems(Array.from(frontier));
        const itemsWithoutParentsToRemove = items
            .filter(i => i.parents
                .filter(p => !(p !== ROOT && p in itemsRemovedFromGraph))
                .length == 0
            );

        for (const itemToRemove of itemsWithoutParentsToRemove) {
            itemsRemovedFromGraph[itemToRemove.id] = itemToRemove;
        }

        const alreadyRemoved = new Set(Object.keys(itemsRemovedFromGraph));
        frontier = new Set(itemsWithoutParentsToRemove
            .flatMap(i => i.children)
            .filter(id => !alreadyRemoved.has(id)));
    }

    return itemsRemovedFromGraph;
}

export class InMemoryDomainState implements DomainState<undefined> {
    private root: Entrypoint<Id> = {
        newChildLocation: DEFAULT_LOCATION,
        children: [],
    };
    private items: { [key: Id]: IndexedItem<Id, Id> | undefined } = {};
    private geoLocations: {[key: string]: NamedGeoLocation} = {};
    private schedule: ScheduleMutable = {};

    private config: AccountConfiguration = {};

    async writeRoot(_tx: undefined, root: Entrypoint<Id>): Promise<void> {
        this.root = root;
    }

    async getRoot(_tx: undefined): Promise<Entrypoint<Id>> {
        return this.root;
    }

    async writeItem(_tx: undefined, item: IndexedItem<Id, Id>): Promise<void> {
        if (item.parents.length === 0) {
            throw new Error(`Don't use writeItem to detach items - use removeItems (id = ${item.id}, deleted = ${item.deleted})`);
        }

        // TODO: add a step here to error if the object is pretty much the same as the one it's replacing (ignoring timestamps) - to try to make things more efficient
        this.items[item.id] = item;
    }

    async removeItems(_tx: undefined, time: ISO8601_DateTime, ...removedEntrypoints: Id[]): Promise<{[key: Id]: IndexedItem<Id, Id>}> {
        const removed = await removeSubGraph(
            () => this.root,
            ids => ids.map(id => this.items[id]).filter(isSomething),
            ...removedEntrypoints
        );

        Object.keys(removed).forEach(id => {
            delete this.items[id as Id];
        });

        return removed;
    }

    async getItem(_tx: undefined, id: Id): Promise<IndexedItem<Id, Id> | undefined> {
        return this.items[id];
    }

    async getItemThrows(_tx: undefined, name: string, id: Id): Promise<IndexedItem<Id, Id>> {
        const item = this.items[id];
        if (!item) {
            throw new Error(`${name} '${id}' does not exist`);
        }
        return item;
    }

    // not used by core domain logic
    // istanbul ignore next
    async getAllItems(_tx: undefined): Promise<IndexedItem<Id, Id>[]> {
        const allItems: IndexedItem<Id, Id>[] = [];
        for (const [k, v] of Object.entries(this.items)) {
            const id = k as Id;
            const item = v as Item<Id, Id>;
            allItems.push({id, ...item});
        }

        return allItems;
    }

    async setConfig<K extends keyof AccountConfiguration>(_tx: undefined, key: K, value: null | AccountConfiguration[K]): Promise<void> {
        if (value === null) {
            delete this.config[key];
        } else {
            this.config[key] = value;
        }
    }

    async getConfig<K extends keyof AccountConfiguration>(_tx: undefined, key: K): Promise<undefined | AccountConfiguration[K]> {
        return this.config[key];
    }

    // not used by core domain logic
    // istanbul ignore next
    async getAllConfig(_tx: undefined): Promise<Readonly<AccountConfiguration>> {
        return {...this.config};
    }

    async addGeoLocation(_tx: undefined, geoLocation: NamedGeoLocation): Promise<void> {
        this.geoLocations[geoLocation.name] = geoLocation;
    }

    async getGeoLocations(_tx: undefined): Promise<Readonly<{[key: string]: NamedGeoLocation}>> {
        return this.geoLocations;
    }

    async removeGeoLocation(_tx: undefined, name: string): Promise<void> {
        delete this.geoLocations[name];
    }

    async setScheduled(_tx: undefined, scheduled: Scheduled<ScheduledId, readonly Lookup<Id>[]>): Promise<void> {
        this.schedule[scheduled.id] = scheduled;
    }

    async getSchedule(_tx: undefined): Promise<Schedule> {
        return this.schedule;
    }

    async removeScheduled(_tx: undefined, id: ScheduledId): Promise<void> {
        delete this.schedule[id];
    }

    async abort(_tx: undefined): Promise<void> {
        throw new Error("in-memory state can't be aborted");
    }
}

const TITLE_STRIP = new RegExp(""
    + "[\\x00-\\x1F\\x7F]" // Control chars
    + "|^\\s*"             // Leading space
    + "|\\s*$"            // Trailing space
    , 'g'
);

const TITLE_MULTIPLE_SPACE = new RegExp("\\s{2,}", 'g');

const DETAIL_STRIP = new RegExp(""
    + "[\\x00-\\x09\\x0B-\\x1F\\x7F]" // Control chars, except newline TODO: consider tabs
    + "|^\\s*"                        // Leading space
    + "|\\s*$"                        // Trailing space,
    , 'g'
);

const DETAIL_STRIP_TRAILING_SPACE_IN_INTERNAL_LINES = new RegExp("[^\\S\\n]*(?=\\n)", 'g');

const DETAIL_MULTIPLE_NEWLINES = new RegExp("\\n{4,}", '');

export function normalizeTitle(title: Title): Title {
    return (title
        .replace(TITLE_STRIP, '')
        .replace(TITLE_MULTIPLE_SPACE, ' ')) as Title;
}

export function normalizeDetail(detail: Detail): Detail {
    return (detail
        .replace(DETAIL_STRIP, '')
        .replace(DETAIL_STRIP_TRAILING_SPACE_IN_INTERNAL_LINES, '')
        .replace(DETAIL_MULTIPLE_NEWLINES, "\n\n\n")) as Detail;
}

async function expandExistingChildren<Tx>(tx: Tx, state: EventState<Tx>, children: readonly Child<Id>[]): Promise<readonly Child<IndexedItem<Id, Id>>[]> {
    const expandedChildren: Child<IndexedItem<Id, Id>>[] = [];

    for (const child of children) {
        const expandedChild = await state.getItem(tx, child);
        if (expandedChild === undefined) {
            continue;
        }

        const childIndexedItem = {...expandedChild, id: child} as unknown as Child<IndexedItem<Id, Id>>;
        expandedChildren.push(childIndexedItem);
    }

    return expandedChildren;
}

function allChildrenDone(ignore: Child<Id>, children: readonly Child<IndexedItem<Id, Id>>[]): boolean {
    for (const child of children) {
        if (child.id === ignore) {
            continue;
        }
        if (!child.todoData) {
            continue;
        }
        if (child.todoData.doneState === 'todo') {
            return false;
        }
    }

    return true;
}

async function cascadeToParents<Tx>(tx: Tx, state: EventState<Tx>, time: ISO8601_DateTime, parents: readonly Parent<Id>[], child: Child<Id>, to: undefined | DoneState): Promise<Timestamped<UiEvent<Id, Id>>[]> {
    const cascaded: Timestamped<UiEvent<Id, Id>>[] = [];

    type QueueItem = { parent: Parent<Id>, cascadeTodoState: boolean};
    const queue: QueueItem[] = parents.slice().map(parent => ({parent, cascadeTodoState: true}));
    let next: undefined | QueueItem;
    while ((next = queue.pop()) !== undefined) {
        // eslint-disable-next-line prefer-const
        let {parent, cascadeTodoState} = next;
        if (parent === ROOT) {
            continue;
        }

        const parentItem = await state.getItem(tx, parent);
        if (parentItem === undefined) {
            continue;
        }

        if (!parentItem.todoData) {
            cascadeTodoState = false;
        }

        const aggregatedSubGraph: AggregatedItemSubgraphData = {
            nodeCount: 0,
            todoCount: 0,
            limitedTodoCount: 0,
            limitedDoneCount: 0,
        };
        const children = await expandExistingChildren(tx, state, parentItem.children);
        for (const c of children) {
            aggregatedSubGraph.nodeCount += (c.aggregatedSubGraph?.nodeCount ?? 0) + 1;
            aggregatedSubGraph.todoCount += (c.aggregatedSubGraph?.todoCount ?? 0) + (c.todoData?.doneState === "todo" ? 1 : 0);

            // this allows a user to break up subtrees by using non-completable parents as an aggregation stop
            // it mostly affects the "next" functionality to ensure that only manageable subgraphs are suggested
            if (c.todoData) {
                aggregatedSubGraph.limitedTodoCount += (c.aggregatedSubGraph?.limitedTodoCount ?? 0) + (c.todoData.doneState === "todo" ? 1 : 0);
                aggregatedSubGraph.limitedDoneCount += (c.aggregatedSubGraph?.limitedDoneCount ?? 0) + (c.todoData.doneState === "done" ? 1 : 0);
            }
        }

        cascaded.push([{
            type: "AutomatedCountAggregation",
            id: parentItem.id,
            aggregatedSubGraph: {...aggregatedSubGraph},
        }, time]);

        if (cascadeTodoState && parentItem.todoData?.doneState === 'todo' && to === 'done' && allChildrenDone(child, children)) {
            const newDoneState = "done";
            await state.writeItem(tx, {...parentItem, todoData: {doneState: newDoneState, updated: time}, aggregatedSubGraph});
            cascaded.push([{
                type: "AutomatedDoneEdited",
                id: parentItem.id,
                state: newDoneState,
            }, time]);
        } else if (cascadeTodoState && parentItem.todoData?.doneState === 'done' && to === 'todo') {
            const newDoneState = "todo";
            await state.writeItem(tx, {...parentItem, todoData: {doneState: newDoneState, updated: time}, aggregatedSubGraph});
            cascaded.push([{
                type: "AutomatedDoneEdited",
                id: parentItem.id,
                state: newDoneState,
            }, time]);
        } else {
            await state.writeItem(tx, {...parentItem, aggregatedSubGraph});
        }

        queue.unshift(...parentItem.parents.map(parent => ({parent, cascadeTodoState})));
    }

    return cascaded;
}

async function assertNoCycle<Tx>(tx: Tx, state: DomainState<Tx>, parents: readonly Parent<Id>[], child: Child<Id>) {
    const queue: Parent<Id>[] = parents.slice();
    let parent: Parent<Id> | undefined;
    while ((parent = queue.pop()) !== undefined) {
        if (parent === ROOT) {
            continue;
        }
        if (parent as Id === child as Id) {
            throw new Error("Cycle detected");
        }
        const parentItem = await state.getItem(tx, parent);
        if (parentItem === undefined) {
            continue;
        }
        queue.unshift(...parentItem.parents);
    }
}

async function expandParentChildRelationship<Tx>(tx: Tx, state: DomainState<Tx>, parent: Parent<Id>, child: Child<Id>): Promise<[Parent<IndexedItem<Id, Id>>, readonly Child<Id>[], IndexedItem<Id, Id>]> {
    const parentItem: Parent<IndexedItem<Id, Id>> = parent === ROOT
        ? ROOT
        : await state.getItemThrows(tx, "Parent", parent) as Parent<IndexedItem<Id, Id>>;

    const parentChildren = parentItem === ROOT
        ? (await state.getRoot(tx)).children
        : parentItem.children;

    if (!parentChildren.includes(child)) {
        throw new Error(`Item '${child}' is not a child of '${parent}'`);
    }
    const childItem = await state.getItemThrows(tx, "Child", child);
    if (!childItem.parents.includes(parent)) {
        throw new Error(`Item '${parent}' is not a parent of '${child}'`);
    }
    return [parentItem, parentChildren, childItem];
}

// TODO: MoveChild cheats by emitting an attach and detach
// NB. a command might result in zero events
export async function processCommand<Tx>(tx: Tx, state: DomainState<Tx>, command: Command): Promise<readonly Event<Id, Id>[]> {
    switch (command.type) {
        case 'AddChild': {
            const normalizedTitle = normalizeTitle(command.noteData.title);
            const normalizedDetail = command.noteData.detail ? normalizeDetail(command.noteData.detail) : command.noteData.detail;
            if (normalizedTitle.length === 0) {
                throw new Error("Empty title");
            }
            if (normalizedDetail !== undefined && normalizedDetail.length === 0) {
                throw new Error("Empty detail");
            }

            let parentItem = undefined;
            if (command.parent !== ROOT) {
                parentItem = await state.getItemThrows(tx, "Parent", command.parent);
            }

            const normalizedNoteData = {...command.noteData,
                title: normalizedTitle,
                detail: normalizedDetail,
            };

            const childItem = await state.getItem(tx, command.child);
            const utcDateTime = nowISO();
            if (childItem !== undefined) {
                const newChildItem: IndexedItem<Id, Id> = {
                    id: command.child,
                    created: utcDateTime,
                    parents: [command.parent],
                    newChildLocation: maybeUndef(command.newChildLocation, DEFAULT_LOCATION, l => l),
                    children: [],
                    noteData: normalizedNoteData,
                    todoData: mapUndef(command.todoData, td => ({...td, updated: utcDateTime})),
                };

                if (!itemsAreEqual(childItem, newChildItem)) {
                    throw new Error(`Duplicate child id '${command.child}'`);
                }

                return [];
            }

            const parentChildIds = parentItem === undefined
                ? (await state.getRoot(tx)).children
                : parentItem.children;

            const parentChildren = await Promise.all(parentChildIds.map(c => state.getItem(tx, c)));
            for (const c of parentChildren) {
                if (c !== undefined && normalizedTitle === c.noteData.title) {
                    return [];
                }
            }

            return [{
                type: "ChildAdded",
                parent: command.parent,
                child: command.child,
                noteData: normalizedNoteData,
                ...(command.todoData === undefined
                    ? {}
                    : {todoData: {...command.todoData, updated: utcDateTime}}
                ),
                ...(command.newChildLocation === undefined
                    ? {}
                    : {newChildLocation: command.newChildLocation}
                ),
                location: command.location,
                ...(command.source === undefined
                    ? {}
                    : {source: command.source}
                )
            }];
        }

        case 'AttachChild': {
            if (command.parent as Id === command.child as Id) {
                throw new Error(`Can't attach '${command.child}' to itself`);
            }

            await state.getItemThrows(tx, "Child", command.child);
            if (command.parent === ROOT) {
                const root = await state.getRoot(tx);
                if (root.children.includes(command.child)) {
                    return [];
                }
            } else {
                const parentItem = await state.getItemThrows(tx, "Parent", command.parent);
                if (parentItem.children.includes(command.child)) {
                    return [];
                }

                await assertNoCycle(tx, state, parentItem.parents, command.child);
            }

            return [{
                type: "ChildAttached",
                parent: command.parent,
                child: command.child,
                location: command.location,
                ...(command.source === undefined
                    ? {}
                    : {source: command.source}
                )
            }];
        }

        case 'DetachChild': {
            await validateDetachChild(tx, state, command.parent, command.child);

            return [{
                type: "ChildDetached",
                parent: command.parent,
                child: command.child,
            }];
        }

        case 'RemoveLayer': {
            await validateDetachChild(tx, state, command.parent, command.child);

            return [{
                type: "LayerRemoved",
                parent: command.parent,
                child: command.child,
            }];
        }

        case 'MassDetachChild': {
            await validateDetachChild(tx, state, command.parent, command.child);

            return [{
                type: "ChildMassDetached",
                parent: command.parent,
                child: command.child,
            }];
        }

        case 'MoveChild': {
            if (command.toParent === command.fromParent) {
                return [];
            }

            const toParentItemNewChildLocation = command.toParent === ROOT
                ? (await state.getRoot(tx)).newChildLocation
                : (await state.getItemThrows(tx, "parent", command.toParent)).newChildLocation;

            return Promise.all([
                processCommand(tx, state, {
                    type: "AttachChild",
                    parent: command.toParent,
                    child: command.child,
                    location: toParentItemNewChildLocation
                }),
                processCommand(tx, state, {type: "DetachChild", parent: command.fromParent, child: command.child}),
            ]).then(both => [...both[0], ...both[1]]);
        }

        case 'EditDone': {
            const item = await state.getItemThrows(tx, "Item", command.item);
            if (item.todoData === undefined) {
                throw new Error(`Item '${command.item}' is not completable`);
            }
            if (item.todoData.doneState === command.to) {
                // cater to concurrent change on two devices
                return [];
            }

            return [{
                type: "DoneEdited",
                id: command.item,
                state: command.to,
                ...(command.source === undefined
                    ? {}
                    : {source: command.source}
                )
            }];
        }

        case 'EditTitle': {
            const normalized = normalizeTitle(command.to);
            if (normalized.length === 0) {
                throw new Error("Empty title");
            }
            const item = await state.getItemThrows(tx, "Item", command.item);
            if (normalized === item.noteData.title) {
                // cater to concurrent change on two devices
                return [];
            }
            if (command.from !== item.noteData.title) {
                throw new Error("Inconsistent data");
            }
            return [{
                type: "TitleEdited",
                id: command.item,
                title: normalized,
            }];
        }

        case 'EditDetail': {
            const normalized = command.to === undefined
                ? undefined
                : normalizeDetail(command.to);
            if (normalized !== undefined && normalized.length === 0) {
                throw new Error("Empty detail");
            }
            const item = await state.getItemThrows(tx, "Item", command.item);
            if (normalized === item.noteData.detail) {
                // cater to concurrent change on two devices
                return [];
            }
            if (command.from !== item.noteData.detail) {
                throw new Error("Inconsistent data");
            }
            return [{
                type: "DetailEdited",
                id: command.item,
                detail: normalized,
            }];
        }

        case 'EditCompletable': {
            const item = await state.getItemThrows(tx, "Item", command.item);
            if (command.to === 'is-completable' && item.todoData !== undefined) {
                // cater to concurrent change on two devices
                return [];
            }
            if (command.to === 'is-not-completable' && item.todoData === undefined) {
                // cater to concurrent change on two devices
                return [];
            }
            return [{
                type: "CompletableEdited",
                id: command.item,
                completable: command.to,
                ...(command.source === undefined
                    ? {}
                    : {source: command.source}
                )
            }];
        }

        case 'ReorderChild': {
            const [,parentChildren,] = await expandParentChildRelationship(tx, state, command.parent, command.child);
            if (parentChildren.indexOf(command.sibling) === -1) {
                throw new Error(`Didn't find sibling '${command.sibling}'`);
            }

            return [{
                ...command,
                type: "ChildrenReorderedRelative",
            }];
        }

        case "UpdateNewChildLocation": {
            if (command.parent !== ROOT) {
                await state.getItemThrows(tx, "Parent", command.parent);
            }
            return [{
                ...command,
                type: "NewChildLocationUpdated",
            }];
        }

        case "SetFallbackGeoLocationPath": {
            return [{
                type: "FallbackGeoLocationPathSet",
                path: command.path,
            }];
        }

        case "AddGeoLocation": {
            const overlapping = findOverlapping(THRESHOLD_SAVING_METRES, command.geoLocation, Object.values(await state.getGeoLocations(tx)));

            if (overlapping.length === 1 && overlapping[0] !== undefined && command.geoLocation.name === overlapping[0].name && command.geoLocation.path === overlapping[0].path) {
                return [];
            }

            if (overlapping.length > 0) {
                throw new Error(`Overlapping locations: ${overlapping.concat(command.geoLocation).map(l => l.name).join(", ")}`);
            }

            return [{
                type: "GeoLocationAdded",
                geoLocation: command.geoLocation,
            }];
        }

        case "EditGeoLocation": {
            const namedGeoLocations = {...await state.getGeoLocations(tx)};
            const matching = namedGeoLocations[command.name];
            const targetMatching = namedGeoLocations[command.geoLocation.name];

            if (matching === undefined) {
                throw new Error(`Could not find location: ${command.name}`);
            }

            if (matching !== targetMatching && targetMatching !== undefined) {
                throw new Error(`Duplicate name: ${command.name}`);
            }

            delete namedGeoLocations[command.name];

            const overlapping = findOverlapping(THRESHOLD_SAVING_METRES, command.geoLocation, Object.values(namedGeoLocations));

            if (overlapping.length > 0) {
                throw new Error(`Overlapping locations: ${overlapping.concat(command.geoLocation).map(l => l.name).join(", ")}`);
            }

            return [{
                type: "GeoLocationEdited",
                name: command.name,
                geoLocation: command.geoLocation,
            }];
        }

        case "RemoveGeoLocation": {
            if (!(command.name in await state.getGeoLocations(tx))) {
                throw new Error(`Could not find location: ${command.name}`);
            }

            return [{
                type: "GeoLocationRemoved",
                name: command.name,
            }];
        }

        case "AddScheduled": {
            if (command.scheduled.id in await state.getSchedule(tx)) {
                throw new Error(`Duplicate scheduled event: ${command.scheduled.id}`);
            }
            
            return [{
                type: "ScheduledAdded",
                scheduled: command.scheduled,
            }];
        }

        case "EditScheduled": {
            if (!(command.scheduled.id in await state.getSchedule(tx))) {
                throw new Error(`Could not find scheduled event: ${command.scheduled.id}`);
            }
            
            return [{
                type: "ScheduledEdited",
                scheduled: command.scheduled,
            }];
        }

        case "RemoveScheduled": {
            if (!(command.id in await state.getSchedule(tx))) {
                throw new Error(`Could not find scheduled event: ${command.id}`);
            }
            
            return [{
                type: "ScheduledRemoved",
                id: command.id,
            }];
        }
        
        case "RecordScheduleExecuted": {
            return [{
                type: "ScheduleExecuted",
                date: command.date,
            }];
        }

        // tell the code coverage check to ignore the default case
        // istanbul ignore next
        default: {
            const _: never = command;
            throw new Error(`Unrecognised command: ${JSON.stringify(command)}`);
        }
    }
}

async function validateDetachChild<Tx>(tx: Tx, state: DomainState<Tx>, parent: Parent<Id>, child: Child<Id>): Promise<void> {
    const [parentItem, , childItem] = await expandParentChildRelationship(tx, state, parent, child);
    if (parentItem?.deleted) {
        throw new Error(`Parent ${parent} is already detached so there's no point detaching a child`);
    }

    if (childItem?.deleted) {
        throw new Error(`Child ${child} is already detached`);
    }

    if (parentItem !== ROOT && parentItem.parents.length === 0) {
        throw new Error("Do not detach from deleted parents");
    }
}

async function attach<Tx>(tx: Tx, state: EventState<Tx>, time: ISO8601_DateTime, parent: Parent<Id>, child: Child<Id>, location?: Location, addChild?: (() => Promise<IndexedItem<Id, Id>>)): Promise<Timestamped<UiEvent<Id, Id>>[]> {
    let parentItem;
    if (parent !== ROOT) {
        parentItem = await state.getItem(tx, parent);
        if (parentItem === undefined) {
            return [];
        }
    }

    const childItem = addChild
        ? await addChild()
        : await state.getItem(tx, child);

    if (childItem === undefined) {
        return [];
    }

    if (!addChild && !childItem.parents.includes(parent)) {
        const newChildItem = {...childItem, parents: [...childItem.parents, parent]};
        await state.writeItem(tx, newChildItem);
    }

    if (parent === ROOT) {
        const root = await state.getRoot(tx);

        if (root.children.includes(child)) {
            logStackTrace("Duplicated child in root");
            return [];
        }

        if (!location) {
            location = root.newChildLocation;
        }

        const newRoot = {
            ...root,
            children: location === "top"
                ? [child, ...root.children]
                : [...root.children, child]
        };
        await state.writeRoot(tx, newRoot);
    } else if (parentItem !== undefined) {
        if (parentItem.children.includes(child)) {
            logStackTrace(`Duplicated child in ${parent}`);
            return [];
        }

        if (!location) {
            location = parentItem.newChildLocation;
        }

        const children = location === "top"
            ? [child, ...parentItem.children]
            : [...parentItem.children, child];
        const newParentItem = {...parentItem, children};
        await state.writeItem(tx, newParentItem);
    }

    return await cascadeToParents(tx, state, time, [parent], child, childItem.todoData?.doneState);
}

async function detach<Tx>(tx: Tx, state: EventState<Tx>, time: ISO8601_DateTime, parent: Parent<Id>, child: Child<Id>, onRemoveItems: (removedItems: {[key: Id]: IndexedItem<Id, Id>}) => (void | Promise<void>)): Promise<Timestamped<UiEvent<Id, Id>>[]> {
    let parentItem;
    if (parent !== ROOT) {
        parentItem = await state.getItem(tx, parent);

        if (parentItem?.deleted) {
            return [];
        }
    }

    const childItem = await state.getItem(tx, child);
    if (childItem !== undefined) {
        if (childItem.deleted) {
            return [];
        }

        const newParents = childItem.parents.filter(p => p !== parent);
        if (newParents.length === 0) {
            // delete the subtree
            // deleting subtrees isn't trivial as an item can be connected to multiple parts of the graph

            // look at our children's parents
            const queue: [Parent<IndexedItem<Id, Id>>, Child<Id>][] = childItem.children.map(c => [childItem as Parent<IndexedItem<Id, Id>>, c]);
            const toRemove: Id[] = [child];
            let rel: [Parent<IndexedItem<Id, Id>>, Child<Id>] | undefined;
            while ((rel = queue.pop()) !== undefined) {
                const [parent, child] = rel;
                if (parent?.deleted) continue;
                const childItem = await state.getItem(tx, child);
                if (childItem === undefined) continue;

                // if I'm the only parent
                if (childItem.parents.length === 1 && childItem.parents[0] === parent?.id) {
                    // add this child's relationships to the queue
                    const rels: [Parent<IndexedItem<Id, Id>>, Child<Id>][] = childItem.children.map(c => [childItem as Parent<IndexedItem<Id, Id>>, c]);
                    queue.unshift(...rels);
                    toRemove.push(childItem.id);
                } else {
                    // it has other parents, so just sever our relationship
                    await state.writeItem(tx, {...childItem, parents: childItem.parents.filter(p => p !== parent?.id)});
                }
            }

            await onRemoveItems(await state.removeItems(tx, time, ...toRemove));
        } else {
            const newChildItem = {...childItem, parents: newParents};
            await state.writeItem(tx, newChildItem);
        }
    }

    if (parent === ROOT) {
        const root = await state.getRoot(tx);
        await state.writeRoot(tx, {...root, children: root.children.filter(c => c !== child)});
    } else if (parentItem !== undefined) {
        const newParentItem = {...parentItem, children: parentItem.children.filter(c => c !== child)};
        await state.writeItem(tx, newParentItem);
    }

    return await cascadeToParents(tx, state, time, [parent], child, childItem?.todoData && parentItem && parentItem.children.length !== 1 ? "done" : undefined);
}

async function editDone<Tx>(tx: Tx, state: EventState<Tx>, time: ISO8601_DateTime, id: Id, newDoneState: DoneState): Promise<Timestamped<UiEvent<Id, Id>>[]> {
    const item = await state.getItem(tx, id);
    if (item === undefined) {
        return [];
    }

    const newItem = {...item, todoData: {...item.todoData, doneState: newDoneState, updated: time}};
    await state.writeItem(tx, newItem);

    return await cascadeToParents(tx, state, time, item.parents, item.id as Child<Id>, newDoneState);
}

/*
 * My new theory about events:
 *   they should represent a single user action and no more
 *   so that replaying an event stream can fix bugs
 */

export type AutomatedChildDetached = {
    type: "AutomatedChildDetached",
    parent: Parent<Id>,
    child: Child<Id>,
};

export type AutomatedDoneEdited = {
    type: "AutomatedDoneEdited",
    id: Id,
    state: DoneState,
};

export type AutomatedCountAggregation = {
    type: "AutomatedCountAggregation",
    id: Id,
    aggregatedSubGraph: AggregatedItemSubgraphData,
};

// The additional events that are not needed to construct the domain state (because they're already applied as part of
// a non-automated event, as part of a transaction), but they're emitted so that the UI knows what happened
// see applyEvent() which takes in Event and returns UiEvent[]
export type UiEvent<P extends object, C extends object>
    = Event<P, C>
    | AutomatedDoneEdited
    | AutomatedChildDetached
    | AutomatedCountAggregation
    ;

export type Timestamped<T> = [T, ISO8601_DateTime];

type EventState<Tx> = Omit<DomainState<Tx>, "getItemThrows">; // NB. Event code shouldn't throw
export async function applyEvents<Tx>(tx: Tx, state: EventState<Tx>, events: readonly Timestamped<Event<Id, Id>>[], onRemoveItems: (removedItems: {[key: Id]: IndexedItem<Id, Id>}) => PromiseOrNot<void>): Promise<Timestamped<UiEvent<Id, Id>>[]> {
    const syntheticEvents: Timestamped<UiEvent<Id, Id>>[] = [];
    for (const event of events) {
        syntheticEvents.push(...await applyEvent(tx, state, event, onRemoveItems));
    }
    return syntheticEvents;
}

async function applyEvent<Tx>(tx: Tx, state: EventState<Tx>, ev: Timestamped<Event<Id, Id>>, onRemoveItems: (removedItems: {[key: Id]: IndexedItem<Id, Id>}) => PromiseOrNot<void>): Promise<Timestamped<UiEvent<Id, Id>>[]> {
    const uiEvents: Timestamped<UiEvent<Id, Id>>[] = [];

    const [event, time] = ev;
    switch (event.type) {
        case "ChildAdded": {
            uiEvents.push(...await add(tx, state, time, event.parent, event.child, event.noteData, event.todoData, event.newChildLocation, event.location));
            uiEvents.unshift(ev);
            break;
        }

        case "ChildAttached": {
            uiEvents.push(...await attach(tx, state, time, event.parent, event.child, event.location));
            uiEvents.unshift(ev);
            break;
        }

        case "ChildDetached":
        case "ChildMassDetached": {
            uiEvents.push(...await detach(tx, state, time, event.parent, event.child, onRemoveItems));
            uiEvents.unshift(ev);
            break;
        }

        case "LayerRemoved": {
            const parentLocation = event.parent === ROOT
                ? (await state.getRoot(tx)).newChildLocation
                : (await state.getItem(tx, event.parent))?.newChildLocation ?? DEFAULT_LOCATION;
            const child = await state.getItem(tx, event.child);
            if (child) {
                for (const grandChild of child.children) {
                    uiEvents.push(...await attach(tx, state, time, event.parent, grandChild, parentLocation));
                }
                uiEvents.push(...await detach(tx, state, time, event.parent, event.child, onRemoveItems));
            }
            uiEvents.unshift(ev);
            break;
        }

        case "DoneEdited": {
            uiEvents.push(...await editDone(tx, state, time, event.id, event.state));
            uiEvents.unshift(ev);
            break;
        }

        case "TitleEdited": {
            const item = await state.getItem(tx, event.id);
            if (item === undefined) {
                await state.abort(tx);
                return [];
            }

            const newItem = {...item, noteData: {...item.noteData, title: event.title}};
            await state.writeItem(tx, newItem);
            uiEvents.unshift(ev);
            break;
        }

        case "DetailEdited": {
            const item = await state.getItem(tx, event.id);
            if (item === undefined) {
                await state.abort(tx);
                return [];
            }

            const newItem = {...item, noteData: {...item.noteData, detail: event.detail}};
            await state.writeItem(tx, newItem);
            uiEvents.unshift(ev);
            break;
        }

        case "CompletableEdited": {
            uiEvents.push(...await editCompletable(tx, state, time, event.id, event.completable));
            uiEvents.unshift(ev);
            break;
        }

        case "ChildrenReordered": {
            if (event.parent === ROOT) {
                const root = await state.getRoot(tx);
                await state.writeRoot(tx, {...root, children: event.children});
            } else {
                const item = await state.getItem(tx, event.parent);
                if (item === undefined) {
                    await state.abort(tx);
                    return [];
                }

                const newItem = {...item, children: event.children};
                await state.writeItem(tx, newItem);
            }
            uiEvents.unshift(ev);
            break;
        }

        case "ChildrenReorderedRelative": {
            if (event.parent === ROOT) {
                const root = await state.getRoot(tx);
                await state.writeRoot(tx, {...root, children: reorder(root.children, event.child, event.position, event.sibling)});
            } else {
                const item = await state.getItem(tx, event.parent);
                if (item === undefined) {
                    await state.abort(tx);
                    return [];
                }

                await state.writeItem(tx, {
                    ...item,
                    children: reorder(item.children, event.child, event.position, event.sibling)
                });
            }

            uiEvents.unshift(ev);
            break;
        }

        case "NewChildLocationUpdated": {
            if (event.parent === ROOT) {
                const root = await state.getRoot(tx);
                await state.writeRoot(tx, {...root, newChildLocation: event.location})
            } else {
                const parentItem = await state.getItem(tx, event.parent);
                if (!parentItem) {
                    await state.abort(tx);
                    return [];
                }

                await state.writeItem(tx, {...parentItem, newChildLocation: event.location})
            }

            uiEvents.unshift(ev);
            break;
        }

        case "FallbackGeoLocationPathSet": {
            await state.setConfig(tx, "geoLocationFallbackPath", event.path);

            uiEvents.unshift(ev);
            break;
        }

        case "GeoLocationAdded": {
            await state.addGeoLocation(tx, event.geoLocation);

            uiEvents.unshift(ev);
            break;
        }

        case "GeoLocationEdited": {
            await state.removeGeoLocation(tx, event.name);
            await state.addGeoLocation(tx, event.geoLocation);

            uiEvents.unshift(ev);
            break;
        }

        case "GeoLocationRemoved": {
            await state.removeGeoLocation(tx, event.name);

            uiEvents.unshift(ev);
            break;
        }

        case "ScheduledAdded": {
            await state.setScheduled(tx, event.scheduled);

            uiEvents.unshift(ev);
            break;
        }

        case "ScheduledEdited": {
            await state.setScheduled(tx, event.scheduled);

            uiEvents.unshift(ev);
            break;
        }

        case "ScheduledRemoved": {
            await state.removeScheduled(tx, event.id);

            uiEvents.unshift(ev);
            break;
        }

        case "ScheduleExecuted": {
            await state.setConfig(tx, "lastScheduleExecution", event.date);

            uiEvents.unshift(ev);
            break;
        }

        default: {
            const _: never = event;
        }
    }

    return uiEvents;
}

async function add<Tx>(tx: Tx, state: EventState<Tx>, time: ISO8601_DateTime, parent: Parent<Id>, child: Child<Id>, noteData: NoteData, todoData?: TodoData, newChildLocation?: Location, location?: Location): Promise<Timestamped<UiEvent<Id, Id>>[]> {
    const normalizedTitle = normalizeTitle(noteData.title);
    const normalizedDetail = noteData.detail ? normalizeDetail(noteData.detail) : noteData.detail;

    const uiEvents: Timestamped<UiEvent<Id, Id>>[] = [];

    uiEvents.push(...await attach(tx, state, time, parent, child, location, async () => {
        const newItem = {
            id: child,
            created: time,
            parents: [parent],
            newChildLocation: maybeUndef(newChildLocation, DEFAULT_LOCATION, l => l),
            children: [],
            noteData: {
                title: normalizedTitle,
                detail: normalizedDetail,
            },
            todoData,
        };
        await state.writeItem(tx, newItem);

        return newItem;
    }));

    return uiEvents;
}

export function reorder(parentChildren: readonly Child<Id>[], child: Child<Id>, position: RelativePosition, sibling: Child<Id>): Child<Id>[] {
    let foundChild = false;
    let foundSibling = false;
    return parentChildren.flatMap((c) => {
        if (c === child) {
            if (foundChild) {
                return [];
            }
            foundChild = true;
            return [];
        } else if (c === sibling) {
            if (foundSibling) {
                return [];
            }
            foundSibling = true;
            if (position === "above") {
                return [child, c];
            } else if (position === "below") {
                return [c, child];
            } else {
                throw new Error(`Unexpected position "${position}"`);
            }
        } else {
            return [c];
        }
    });
}

async function editCompletable<Tx>(tx: Tx, state: EventState<Tx>, time: ISO8601_DateTime, id: Id, completable: Completable): Promise<Timestamped<UiEvent<Id, Id>>[]> {
    const item = await state.getItem(tx, id);
    if (item === undefined) {
        await state.abort(tx);
        return [];
    }

    const newTodoData: TodoData | undefined = completable === 'is-completable'
        ? {...item.todoData, doneState:  'todo', updated: time}
        : undefined;
    const newItem = {...item, todoData: newTodoData};
    await state.writeItem(tx, newItem);
    return cascadeToParents(tx, state, time, item.parents, id as Child<Id>, undefined);
}

function itemsAreEqual(a: IndexedItem<Id, Id>, b: IndexedItem<Id, Id>): boolean {
    return a.id === b.id
        && a.noteData.title === b.noteData.title
        && a.noteData.detail === b.noteData.detail;
}

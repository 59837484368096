import MiniSearch, {Options, SearchOptions, SearchResult} from "minisearch";
import {
    AggregatedItemSubgraphData,
    Child,
    DEFAULT_LOCATION,
    Detail,
    DoneState,
    Entrypoint,
    Id,
    IndexedItem,
    Location,
    Lookup,
    Parent,
    ROOT,
    Title
} from "../common/domain/data";
import {Store} from "./store";
import {mapUndef} from "../common/util";
import {ROOT_TITLE_VERBOSE} from "./global";
import {domainTxReadonly} from "./ui-state";
import {domainState} from "./domain-state";
import {ISO8601_DateTime} from "../common/datetime";
import {ItemGraph, IxItemThin} from "./types";
import {toIds, toLookups} from "./view/util";

const SEARCH_INDEX_VERSION = '1'; // Increment when the indices change

export type SearchIndex = {[key: string]: string};

function searchResultToItem(searchResult: ItemSearchResult): ItemGraph {
    return {
        id: searchResult.id === ROOT_SEARCH_ID ? ROOT : searchResult.id,
        parents: searchResult.parents,
        children: searchResult.children as Child<Id>[],
        created: searchResult.created,
        ...(searchResult.deleted === undefined ? {} : {deleted: searchResult.deleted}),
        newChildLocation: searchResult.newChildLocation,
        noteData: {
            title: searchResult["noteData.title"],
            detail: searchResult["noteData.detail"],
        },
        ...(searchResult["todoData.doneState"] === undefined ? {} : {
            todoData: {
                doneState: searchResult["todoData.doneState"],
                updated: searchResult["todoData.updated"]!,
            }
        }),
        ...(searchResult.aggregatedSubGraph === undefined ? {} : {aggregatedSubGraph: searchResult.aggregatedSubGraph})
    };
}

const ROOT_SEARCH_ID = "__root_search_id__" as Id;
const ROOT_CREATED = "1983-01-18T19:00:00.000Z" as ISO8601_DateTime;
function createSearchRoot(rootEntrypoint: Entrypoint<Id>): IndexedItem<Id, Id> {
    return {
        id: ROOT_SEARCH_ID,
        created: ROOT_CREATED,
        newChildLocation: rootEntrypoint.newChildLocation,
        parents: [],
        children: rootEntrypoint.children,
        noteData: {
            title: ROOT_TITLE_VERBOSE,
            detail: "The root of the graph" as Detail,
        },
    };
}



const stopWords = new Set([
    "a",
    "about",
    "above",
    "after",
    "again",
    "against",
    "all",
    "also",
    "am",
    "an",
    "and",
    "any",
    "are",
    "as",
    "at",
    "be",
    "because",
    "been",
    "before",
    "being",
    "below",
    "between",
    "both",
    "but",
    "by",
    "com",
    "could",
    "de",
    "did",
    "do",
    "does",
    "doing",
    "down",
    "during",
    "each",
    "en",
    "few",
    "for",
    "from",
    "further",
    "had",
    "has",
    "have",
    "having",
    "he",
    "he'd",
    "he'll",
    "he's",
    "her",
    "here",
    "here's",
    "hers",
    "herself",
    "him",
    "himself",
    "his",
    "how",
    "how's",
    "however",
    "i",
    "i'd",
    "i'll",
    "i'm",
    "i've",
    "if",
    "in",
    "into",
    "is",
    "it",
    "it's",
    "its",
    "itself",
    "la",
    "let's",
    "me",
    "more",
    "most",
    "my",
    "myself",
    "no",
    "nor",
    "not",
    "of",
    "on",
    "once",
    "only",
    "or",
    "other",
    "ought",
    "our",
    "ours",
    "ourselves",
    "out",
    "over",
    "own",
    "same",
    "she",
    "she'd",
    "she'll",
    "she's",
    "should",
    "so",
    "some",
    "such",
    "than",
    "that",
    "that's",
    "the",
    "their",
    "theirs",
    "them",
    "themselves",
    "then",
    "there",
    "there's",
    "these",
    "they",
    "they'd",
    "they'll",
    "they're",
    "they've",
    "this",
    "those",
    "through",
    "to",
    "too",
    "und",
    "under",
    "until",
    "up",
    "very",
    "was",
    "we",
    "we'd",
    "we'll",
    "we're",
    "we've",
    "were",
    "what",
    "what's",
    "whatever",
    "when",
    "when's",
    "where",
    "where's",
    "whether",
    "which",
    "while",
    "who",
    "who's",
    "whom",
    "why",
    "why's",
    "will",
    "with",
    "would",
    "www",
    "you",
    "you'd",
    "you'll",
    "you're",
    "you've",
    "your",
    "yours",
    "yourself",
    "yourselves",
]);

type ItemSearchResult = Omit<SearchResult, "id"> & {
    id: Id,
    created: ISO8601_DateTime,
    deleted?: ISO8601_DateTime
    parents: Parent<Id>[],
    children: Id[],
    newChildLocation: Location,
    'noteData.title': Title,
    'noteData.detail': Detail,
    'todoData.doneState'?: DoneState,
    'todoData.updated'?: ISO8601_DateTime,
    aggregatedSubGraph?: AggregatedItemSubgraphData,
};

const cfg: Options<IxItemThin> = {
    fields: ['noteData.title', 'noteData.detail'],
    storeFields: ['id', 'parents', 'children', 'created', 'deleted', 'newChildLocation', 'noteData.title', 'noteData.detail', 'todoData.doneState', 'todoData.updated', 'aggregatedSubGraph'],
    extractField: (document, fieldName) =>
        fieldName
            .split('.')
            .reduce((doc, key) => doc && (doc as any)[key], document) as unknown as string,
    tokenize: str => MiniSearch.getDefault('tokenize')(str),
    processTerm: (term, fieldName) => stopWords.has(term) ? null : MiniSearch.getDefault('processTerm')(term, fieldName),
    searchOptions: {
        boost: { 'noteData.title': 3 },
        fuzzy: 0.2,
        prefix: term => term.length > 1,
        tokenize: str => MiniSearch.getDefault('tokenize')(str),
        processTerm: term => MiniSearch.getDefault('processTerm')(term),
    },
};

export interface Search {
    add(...items: ItemGraph[]): void;

    remove(...items: ItemGraph[]): void;

    commit(): void;

    search(inputValue: string, searchConfig?: SearchOptions): ItemGraph[];

    get(root: null): ItemGraph<null>;
    get(id: Id): ItemGraph<Id> | undefined;
    get(lookup: Lookup<Id>): ItemGraph<Lookup<Id>> | undefined;
    get(lookup: Lookup<Id>): ItemGraph<Lookup<Id>> | ItemGraph<Id> | undefined;

    rebuild():Promise<void> ;
}

export class SearchImpl implements Search {
    constructor(private miniSearch: MiniSearch<IxItemThin>) {}

    add(...items: ItemGraph[]): void {
        this.miniSearch.addAll(items.map(normalize));
    }

    remove(...items: ItemGraph[]): void {
        this.miniSearch.removeAll(items.map(normalize));
    }

    commit(): void {
        commitSearch(this.miniSearch);
    }

    search(inputValue: string, searchConfig?: SearchOptions): ItemGraph[] {
        return this.miniSearch.search(inputValue, searchConfig).map(r => searchResultToItem(r as unknown as ItemSearchResult));
    }

    get(root: null): ItemGraph<null>;
    get(id: Id): ItemGraph<Id> | undefined;
    get(lookup: Lookup<Id>): ItemGraph<Lookup<Id>> | undefined;
    get(lookup: Lookup<Id>): ItemGraph<Lookup<Id>> | ItemGraph<Id> | undefined {
        if (lookup === ROOT) {
            return searchResultToItem(this.miniSearch.getStoredFields(ROOT_SEARCH_ID) as unknown as ItemSearchResult);
        } else {
            return mapUndef(this.miniSearch.getStoredFields(lookup) as unknown as ItemSearchResult, searchResultToItem);
        }
    }

    async rebuild():Promise<void> {
        this.miniSearch = await createFresh();
    }
}

export async function searchFactory(): Promise<Search> {
    const searchIndex = Store.getItem("searchIndex");
    const searchIndexJson = searchIndex[SEARCH_INDEX_VERSION];

    if (searchIndexJson) {
        try {
            return new SearchImpl(MiniSearch.loadJSON(searchIndexJson, cfg));
        } catch (e) {
            if (e instanceof Error && e.message.indexOf("incompatible version") !== -1) {
                return new SearchImpl(await createFresh());
            }
            throw e;
        }
    } else {
        return new SearchImpl(await createFresh());
    }
}

async function createFresh(): Promise<MiniSearch<IndexedItem<Id, Id, Id>>> {
    const miniSearch = new MiniSearch(cfg);
    miniSearch.add(createSearchRoot({newChildLocation: DEFAULT_LOCATION, children: []})); // make sure we always have a root

    return domainTxReadonly(async tx => {
        const rootEntrypoint = await domainState.getRoot(tx)
            .catch(() => ({newChildLocation: DEFAULT_LOCATION, children: []} as Entrypoint<Id>));

        miniSearch.remove(miniSearch.getStoredFields(ROOT_SEARCH_ID)! as unknown as IndexedItem<Id, Id, Id>);
        miniSearch.add(createSearchRoot(rootEntrypoint));

        let ids = [...rootEntrypoint.children];
        while (ids.length > 0) {
            const items = await tx.table("items").bulkGet(ids);
            ids = [];
            for (const item of items) {
                if (item !== undefined && item.parents.length > 0) {
                    if (!miniSearch.has(item.id)) {
                        miniSearch.add(item);
                    }
                    ids.push(...item.children);
                }
            }
        }

        commitSearch(miniSearch);
        return miniSearch;
    });
}

function normalize(item: ItemGraph): IxItemThin {
    return {
        ...item,
        id: item.id === ROOT ? ROOT_SEARCH_ID : item.id,
        parents: toLookups(item.parents) as readonly Parent<Id>[],
        children: toIds(item.children) as readonly Child<Id>[],
    };
}

function commitSearch(miniSearch: MiniSearch<IxItemThin>): void {
    const versioned: SearchIndex = {};
    versioned[SEARCH_INDEX_VERSION] = JSON.stringify(miniSearch);
    Store.setItem("searchIndex", versioned);
}
import {Id} from "../common/domain/data";
import {IxItemThin} from "./types";
import {FancyTransaction} from "./db";
import {lru} from "./lru";

export async function getItem(tx: FancyTransaction, id: Id): Promise<IxItemThin | undefined> {
    const cached = lru.get(id);
    if (cached !== undefined) {
        return JSON.parse(JSON.stringify(cached)) as IxItemThin; // deep copy to avoid shared mutable state
    }
    const miss = await tx.table("items").get(id);
    if (miss !== undefined) {
        lru.set(id, miss);
    }

    return miss;
}

import {Title} from "../common/domain/data";
import {Model} from "./types";

type AppEnv = {
    VERSION: string,
    API_URL: string,
    WEBSOCKET_URL: string,
    getModel: () => Model,
};

declare global {
    // eslint-disable-next-line no-var
    var appEnv: AppEnv;
}

export const DAYS_UNTIL_DONE_IS_OLD = 7;

export const ROOT_TITLE = "◉" as Title;
export const ROOT_TITLE_VERBOSE = `${ROOT_TITLE} (top level)` as Title;


import {ToolsComponent, ToolsPath, toolsToUiRepr, toUrlRepr} from "../../../common/urlpath";
import React, {ReactElement, useEffect, useState} from "react";
import {Button, Card, ListGroup, ListGroupItem} from "react-bootstrap";
import {IxItemThin, Model} from "../../types";
import {applyCommands, domainTx, sendCommands} from "../../ui-state";
import {UiEvent} from "../../../common/domain/logic";
import {AggregatedItemSubgraphData, Child, Command, Id, ROOT} from "../../../common/domain/data";
import {isSomething} from "../../../common/util";
import {writeFragment} from "../../fragment";
import {reachableFromRoot} from "../../../common/domain/graph";
import {db} from "../../db";
import {now, serializeUtcIsoDateTime} from "../../../common/datetime";
import {DAYS_UNTIL_DONE_IS_OLD, ROOT_TITLE} from "../../global";
import {domainState} from "../../domain-state";
import {ItemTitle} from "../../components/ItemTitle";
import {CardSpinner} from "../../components/CardSpinner";
import {mutable} from "../../mutable";


export function toolsPathLink(listComponent: ToolsComponent): ReactElement {
    return (
        <ListGroupItem key={`tools-${listComponent}`}>
            <a key={"link"} href={`#${toUrlRepr({type: "Tools", component: listComponent})}`}>
                {toolsToUiRepr(listComponent)}
            </a>
        </ListGroupItem>
    );
}

export function toolsView(model: Model, path: ToolsPath) {
    const toolsComponent = path.component;
    const ref = serializeUtcIsoDateTime(new Date(now().getTime() - 1000*60*60*24*DAYS_UNTIL_DONE_IS_OLD));

    switch (toolsComponent) {
        case undefined:
            return <ListGroup key={"tools"} className={"link-list"}>
                <ListGroupItem>{toolsPathLink("next")}</ListGroupItem>
                <ListGroupItem>{toolsPathLink("old-done")}</ListGroupItem>
                <ListGroupItem>{toolsPathLink("deleted")}</ListGroupItem>
            </ListGroup>;

        case "next":
            return <Next itemId={path.id}/>;

        case "old-done":
            return <ItemQuery
                getItems={() => db
                    .then(db => db.transaction("readonly", ["items"], tx => tx.table("items").toArray()))
                    .then(items => items.filter(item => {
                        if (item.deleted) return false;

                        const todoData = item.todoData;
                        if (todoData === undefined) return false;

                        return todoData.doneState === "done" && todoData.updated < ref;
                    }))}
                renderResults={results => <ListGroup>
                    <ListGroupItem key={"delete-all"}><Button onClick={ev => {
                        ev.preventDefault();
                        const listIds = results.map(item => item.id);
                        void domainTx(async tx => {
                            const commands: Command[] = [];
                            const events: UiEvent<Id, Id>[] = [];

                            for (const item of results) {
                                const freshItem = await tx.table("items").get(item.id);
                                if (freshItem?.deleted) continue;

                                const isReachable = await reachableFromRoot(
                                    async ids => (await tx.table("items").bulkGet(ids as Id[])).filter(isSomething),
                                    item.parents,
                                    listIds
                                );

                                if (!isReachable) continue;

                                for (const parent of item.parents) {
                                    if (parent !== ROOT) { // TODO: remove this branch, it's just here for debugging
                                        const parentItem = await tx.table('items').get(parent);
                                        if (parentItem !== undefined && (parentItem.parents.length === 0 || parentItem.deleted)) {
                                            // debugger;
                                            continue;
                                        }
                                    }
                                    const command: Command = {
                                        type: "MassDetachChild",
                                        parent,
                                        child: item.id as Child<Id>,
                                    };
                                    const cmdEvents = await applyCommands(tx, [command]);

                                    commands.push(command);
                                    events.push(...cmdEvents);
                                }
                            }

                            return [commands, events] as const;
                        })
                            .then(async results => {
                                // NB. do network IO in a new promise to avoid breaking the IndexedDB transaction
                                const [commands, events] = results;
                                await sendCommands(commands, events);
                            })
                            .then(() => writeFragment(`#${toUrlRepr({type: "Tools"})}`));
                    }}>Delete all</Button></ListGroupItem>

                    {results.map(item =>
                        <ListGroupItem key={"id-" + item.id}>
                            <a href={"#" + toUrlRepr({
                                type: "Graph",
                                breadcrumbs: [item.id]
                            })}>
                                {item.noteData.title}
                            </a>
                        </ListGroupItem>)
                    }
                </ListGroup>}
            />;

        case "deleted":
            return <ItemQuery
                getItems={() => db
                    .then(db => db.transaction("readonly", ["items"], tx => tx.table("items").toArray()))
                    .then(items => items.filter(item => !!item.deleted))}
                renderResults={results =>
                    <ListGroup>
                        {results.map(item =>
                            <ListGroupItem key={item.id}><a href={`#${toUrlRepr({
                                type: "Graph",
                                breadcrumbs: [item.id]
                            })}`}>{item.noteData.title}</a></ListGroupItem>)}
                    </ListGroup>
                }
            />;

        default:
            const _: never = toolsComponent;
            throw new Error(`Unexpected object: ${JSON.stringify(toolsComponent)}`);
    }
}

type ItemQueryProps = {getItems: () => Promise<readonly IxItemThin[]>, renderResults: (results: readonly IxItemThin[]) => ReactElement}
function ItemQuery({getItems, renderResults}: ItemQueryProps): ReactElement {
    const [results, setResults] = useState<null | readonly IxItemThin[]>(null);

    useEffect(() => {
        void getItems()
            .then(items => {
                setResults(items);
            });
    }, [getItems]);

    return <Display results={results} />;

    type DisplayProps = {results: null | readonly IxItemThin[]};
    function Display({results}: DisplayProps): ReactElement {
        if (results === null) {
            return <CardSpinner text={"Loading..."} />;
        }

        if (results.length === 0) {
            return <Card><Card.Body>No items found</Card.Body></Card>;
        }

        return renderResults(results);
    }
}

type NextProps = {itemId?: Id};
function Next({itemId}: NextProps): ReactElement {
    const underItem = (itemId && mutable.search.get(itemId)) || mutable.search.get(ROOT);

    return <>
        <ItemTitle
            initialState={{
                defaultHighlight: 0,
                chosenItem: underItem,
                input: underItem.noteData.title,
            }}

            onItemChosen={result => {
                const displayRoot = result === null
                    ? {} // no result => display root
                    : result.id === ROOT
                        ? {} // root selected => display root
                        : {id: result.id};

                writeFragment(`#${(toUrlRepr({
                    type: "Tools",
                    component: "next",
                    ...displayRoot,
                }))}`)
            }}
        />
        <ItemQuery
            key={itemId} // This is hacky, it's declaring a dependency via the "key" property
            getItems={() => domainTx(async tx => {
                const children = (itemId === undefined || itemId === ROOT ? await domainState.getRoot(tx) : await domainState.getItem(tx, itemId))?.children ?? [];
                const queue: Child<Id>[] = [...children];
                const seen: Child<Id>[] = [];
                const items: IxItemThin[] = [];
                let child;
                while ((child = queue.pop()) !== undefined) {
                    if (seen.includes(child)) continue;
                    seen.push(child);
                    const childItem = await domainState.getItem(tx, child);
                    if (!childItem) continue;
                    queue.unshift(...childItem.children);
                    if ((childItem.aggregatedSubGraph?.limitedTodoCount ?? 0) > 0) {
                        items.push(childItem);
                    }
                }

                return items;
            })
                .then(items => {
                    const copy = [...items];
                    copy.sort((x, y) => {
                        // remember that "order by" means bigger numbers later in the structure, so returning 1 makes it later

                        const xTodoCount = (x.aggregatedSubGraph as AggregatedItemSubgraphData).limitedTodoCount;
                        const xDoneCount = (x.aggregatedSubGraph as AggregatedItemSubgraphData).limitedDoneCount;
                        const xPercentage = (xTodoCount * 100) / (xTodoCount + xDoneCount);
                        const yTodoCount = (y.aggregatedSubGraph as AggregatedItemSubgraphData).limitedTodoCount;
                        const yDoneCount = (y.aggregatedSubGraph as AggregatedItemSubgraphData).limitedDoneCount;
                        const yPercentage = (yTodoCount * 100) / (yTodoCount + yDoneCount);

                        // order by higher percentage
                        if (xPercentage === yPercentage) {
                            // then by todo count
                            if (xTodoCount === yTodoCount) {
                                // then by age
                                if (x.created === y.created) {
                                    return 0;
                                } else if (x.created < y.created) {
                                    return 1;
                                } else {
                                    return -1;
                                }
                            } else if (xTodoCount > yTodoCount) {
                                return 1;
                            } else {
                                return -1;
                            }
                        } else if (xPercentage > yPercentage) {
                            return 1;
                        } else {
                            return -1;
                        }
                    });

                    return copy;
                })}
            renderResults={results => {
                return <Card><Card.Body className={"table-responsive"}>
                    <table className={"table"}>
                        <thead>
                        <tr>
                            <th>Parents</th>
                            <th>Title</th>
                            <th>Todos</th>
                            <th>Progress</th>
                        </tr>
                        </thead>
                        <tbody>
                        {results.map(item => {
                            const done = item.aggregatedSubGraph?.limitedDoneCount ?? 0;
                            const todo = item.aggregatedSubGraph?.limitedTodoCount ?? 0;
                            const total = todo + done;
                            return <tr key={item.id}>
                                <td>{item.parents.map(p => p === ROOT ? ROOT_TITLE : mutable.search.get(p)?.noteData.title).join(", ")}</td>
                                <td><a href={`#${toUrlRepr({
                                    type: "Graph",
                                    breadcrumbs: [item.id]
                                })}`}>{item.noteData.title}</a></td>
                                <td>{done}/{total}</td>
                                <td>{Math.round((done / total) * 100)}%</td>
                            </tr>;
                        })}
                        </tbody>
                    </table>
                </Card.Body></Card>;
            }}
        />
    </>;
}
import {ItemGraph, Msg} from "./types";
import {WSProtocol} from "./websocket/wsprotocol";
import {Search} from "./search";
import {SearchOptions} from "minisearch";
import {Id, Lookup} from "../common/domain/data";

class SearchUninitialized implements Search {
    add(..._items: ItemGraph[]): void {
        throw new Error("Not yet initialized");
    }
    remove(..._items: ItemGraph[]): void {
        throw new Error("Not yet initialized");
    }
    commit(): void {
        throw new Error("Not yet initialized");
    }
    search(_inputValue: string, _searchConfig?: SearchOptions): ItemGraph[] {
        throw new Error("Not yet initialized");
    }

    get(root: null): ItemGraph<null>;
    get(id: Id): ItemGraph<Id> | undefined;
    get(lookup: Lookup<Id>): ItemGraph<Lookup<Id>> | undefined;
    get(_lookup: Lookup<Id>): ItemGraph<Lookup<Id>> | ItemGraph<Id> | undefined {
        throw new Error("Not yet initialized");
    }

    async rebuild(): Promise<void> {
        throw new Error("Not yet initialized");
    }
}

export const mutable = {
    send: (() => {
        throw new Error("Not yet initialized");
    }) as (msg: Msg) => void,
    wsConnection: undefined as undefined | WSProtocol,
    doubleClickHack: null as null | ReturnType<typeof setTimeout>,
    search: new SearchUninitialized() as Search,
}
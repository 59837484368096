import {Id} from "./domain/data";

export type GraphPath = { type: "Graph", breadcrumbs: Id[] };
export type SettingsPath = { type: "Settings", component?: SettingsComponent };
export type LoginErrorPath = { type: "LoginError", error: LoginError };
export type DebugPath = { type: "Debug", component?: DebugComponent };
export type ToolsPath = { type: "Tools", component?: ToolsComponent, id?: Id };
export type LocationPath = { type: "Location", component?: LocationComponent };
export type SchedulingPath = { type: "Scheduling" };
export type HelpPath = { type: "Help", component?: HelpComponent };
export type UrlPath
    = { type: "NotFound", path: string }
    | { type: "Empty" }
    | GraphPath
    | ToolsPath
    | LocationPath
    | SchedulingPath
    | SettingsPath
    | LoginErrorPath
    | HelpPath
    | DebugPath
    ;

export type ToolsComponent
    = "next"
    | "old-done"
    | "deleted"
    ;

export type LocationComponent
    = "nearby"
    ;

export type SettingsComponent
    = "login"
    | "token-submission"
    | "bad-session"
    ;

export type LoginError
    = "token-missing"
    | "token-invalid"
    | "token-expired"
    ;

export type HelpComponent
    = "about"
    | "keyboard";

export type DebugComponent
    = "synchronous-error"
    | "asynchronous-error"
    ;

export function toUrlRepr(urlPath: UrlPath): string {
    switch (urlPath.type) {
        case "Empty":
            return "/";
        case "Graph":
            return `/graph/${urlPath.breadcrumbs.join("/")}`;
        case "Tools":
            return `/tools${urlPath.component === undefined ? "" : "/" + urlPath.component}${urlPath.component && urlPath.id ? "/" + urlPath.id : ""}`;
        case "Location":
            return `/location${urlPath.component === undefined ? "" : "/" + urlPath.component}`;
        case "Scheduling":
            return "/scheduling";
        case "Settings":
            return `/settings${urlPath.component === undefined ? "" : "/" + urlPath.component}`;
        case "LoginError":
            return `/login-error/${urlPath.error}`;
        case "Help":
            return `/help${urlPath.component === undefined ? "" : "/" + urlPath.component }`;
        case "Debug":
            return `/debug${urlPath.component === undefined ? "" : "/" + urlPath.component}`;
        case "NotFound":
            return "/";
        default: {
            const _: never = urlPath;
            throw new Error(`Unexpected object: ${JSON.stringify(urlPath)}`);
        }
    }
}

export function toUiRepr(urlPath: UrlPath): string {
    switch (urlPath.type) {
        case "Empty": {
            return "";
        }

        case "Graph": {
            return "Graph";
        }

        case "Tools": {
            if (urlPath.component === undefined) {
                return "Tools";
            }

            return `Tools / ${(toolsToUiRepr(urlPath.component))}`;
        }

        case "Location": {
            if (urlPath.component === undefined) {
                return "Location";
            }

            return `Location / ${(locationToUiRepr(urlPath.component))}`;
        }

        case "Scheduling": {
            return "Scheduling";
        }

        case "Settings": {
            if (urlPath.component === undefined) {
                return "Settings";
            }

            return `Settings / ${settingsToUiRepr(urlPath.component)}`;
        }

        case "LoginError": {
            return `Login Error / ${loginErrorToUiRepr(urlPath.error)}`;
        }

        case "Help":
            if (urlPath.component === undefined) {
                return "Help";
            }

            return `Help / ${helpToUiRepr(urlPath.component)}`;

        case "Debug":
            if (urlPath.component === undefined) {
                return "Debug";
            }

            return `Debug / ${(debugToUiRepr(urlPath.component))}`;

        case "NotFound":
            return "Not found";

        default: {
            const _: never = urlPath;
            throw new Error(`Unexpected object: ${JSON.stringify(urlPath)}`);
        }
    }
}

export function toolsToUiRepr(toolsComponent: ToolsComponent): string {
    switch (toolsComponent) {
        case "next":
            return "Next items to achieve closure";
        case "old-done":
            return "Old done items";
        case "deleted":
            return "Deleted items";

        default: {
            const _: never = toolsComponent;
            throw new Error(`Unexpected object: ${JSON.stringify(toolsComponent)}`);
        }
    }
}

export function locationToUiRepr(locationComponent: LocationComponent): string {
    switch (locationComponent) {
        case "nearby":
            return "Nearby";

        default: {
            const _: never = locationComponent;
            throw new Error(`Unexpected object: ${JSON.stringify(locationComponent)}`);
        }
    }
}

export function settingsToUiRepr(settingsComponent: SettingsComponent): string {
    switch (settingsComponent) {
        case "login":
            return "Login";
        case "token-submission":
            return "Token submission";
        case "bad-session":
            return "Bad login";

        default: {
            const _: never = settingsComponent;
            throw new Error(`Unexpected object: ${JSON.stringify(settingsComponent)}`);
        }
    }
}

export function loginErrorToUiRepr(loginErrorComponent: LoginError): string {
    switch (loginErrorComponent) {
        case "token-missing":
            return "Token missing";
        case "token-invalid":
            return "Token invalid";
        case "token-expired":
            return "Token expired";

        default: {
            const _: never = loginErrorComponent;
            throw new Error(`Unexpected object: ${JSON.stringify(loginErrorComponent)}`);
        }
    }
}

export function helpToUiRepr(helpComponent: HelpComponent): string {
    switch (helpComponent) {
        case "about":
            return "About";
        case "keyboard":
            return "Keyboard shortcuts";

        default: {
            const _: never = helpComponent;
            throw new Error(`Unexpected object: ${JSON.stringify(helpComponent)}`);
        }
    }
}

export function debugToUiRepr(debugComponent: DebugComponent): string {
    switch (debugComponent) {
        case "synchronous-error":
            return "Synchronous error";
        case "asynchronous-error":
            return "Asynchronous error"

        default: {
            const _: never = debugComponent;
            throw new Error(`Unexpected object: ${JSON.stringify(debugComponent)}`);
        }
    }
}

type ParserState = "init" | "component";
export function parseUrlPath(str: string): UrlPath {
    if (str === "") {
        str = "/";
    }

    const parts = str.split("/");
    let state: ParserState = "init";
    let part;
    while ((part = parts.shift()) !== undefined) {
        switch (state) {
            case "init":
                if (part !== "") {
                    break;
                }
                state = "component";
                break;

            case "component":
                switch (part) {
                    case "":
                        return {type: "Empty"};

                    case "graph":
                        return {type: "Graph", breadcrumbs: parts.map(b => b.trim()).filter(b => b.length > 0) as Id[]};

                    case "tools":
                        if (parts.length === 0) {
                            return {type: "Tools"};
                        }

                        const toolsComponent = parts[0];
                        switch (toolsComponent) {
                            case "next":
                                if (parts.length !== 1 && parts.length !== 2) {
                                    break;
                                }
                                const id = parts[1];
                                return {type: "Tools", component: toolsComponent, ...(id ? {id: id as Id} : {})};
                            case "old-done":
                            case "deleted":
                                if (parts.length !== 1) {
                                    break;
                                }

                                return {type: "Tools", component: toolsComponent};

                            default:
                                break;
                        }
                        break;

                    case "location":
                        if (parts.length === 0) {
                            return {type: "Location"};
                        }

                        if (parts.length !== 1) {
                            break;
                        }

                        const locationComponent = parts[0];
                        switch (locationComponent) {
                            case "nearby":
                                return {type: "Location", component: locationComponent};

                            default:
                                break;
                        }
                        break;

                    case "scheduling":
                        if (parts.length !== 0) {
                            break;
                        }
                        return {type: "Scheduling"};

                    case "settings":
                        if (parts.length === 0) {
                            return {type: "Settings"};
                        }

                        if (parts.length !== 1) {
                            break;
                        }

                        const settingsComponent = parts[0];
                        switch (settingsComponent) {
                            case "login":
                            case "token-submission":
                            case "bad-session":
                                return {type: "Settings", component: settingsComponent};

                            default:
                                break;
                        }
                        break;

                    case "login-error":
                        if (parts.length !== 1) {
                            break;
                        }

                        const loginError = parts[0];
                        switch (loginError) {
                            case "token-missing":
                            case "token-invalid":
                            case "token-expired":
                                return {type: "LoginError", error: loginError};

                            default:
                                break;
                        }
                        break;

                    case "help":
                        if (parts.length === 0) {
                            return {type: "Help"};
                        }

                        if (parts.length !== 1) {
                            break;
                        }

                        const helpComponent = parts[0];
                        switch (helpComponent) {
                            case "about":
                            case "keyboard":
                                return {type: "Help", component: helpComponent};

                            default:
                                break;
                        }

                        break;

                    case "debug":
                        if (parts.length === 0) {
                            return {type: "Debug"};
                        }

                        if (parts.length !== 1) {
                            break;
                        }

                        const debugComponent = parts[0];
                        switch (debugComponent) {
                            case "synchronous-error":
                            case "asynchronous-error":
                                return {type: "Debug", component: debugComponent};

                            default:
                                break;
                        }
                        break;

                    default:
                        break;
                }
                break;

            default: {
                throw new Error(`Unexpected object: ${JSON.stringify(state)}`);
            }
        }
    }

    return {type: "NotFound", path: str};
}

import {WebSocketApi} from "./websocket";
import {postItemRemoval} from "../util";
import {
    ClientVersion,
    CmdIdentifier,
    ERROR_CODE_BAD_CLIENT_VERSION,
    ERROR_CODE_BAD_LOGIN,
    SessionId,
    WSClientState,
    WSClientToServer,
    WSDomainCommands,
    WSServerToClient
} from "../../common/protocol";
import {ExpandedEvent} from "../types";
import {applyEvents, DomainState} from "../../common/domain/logic";
import {AccountConfiguration, Command, ROOT} from "../../common/domain/data";
import {expandEvent} from "../data-load";
import {newPromise} from "../../common/util";
import {FancyTransaction} from "../db";
import {mutable} from "../mutable";
import {clientDebug} from "../client-logging";
import {forceUpgradeButDontRefresh} from "../service-worker";
import {lru} from "../lru";
import {Store} from "../store";
import {domainTx} from "../ui-state";
import {uuidv4} from "../../common/uuid";

export type RejectionReason
    = "Bad login"
    | "Connection error"
    ;

export class WSProtocol {
    private ws?: WebSocketApi;
    private connected = false;
    private readonly session: SessionId;
    private readonly onDesync: () => void;
    private readonly onConnected: () => void;
    private readonly onDisconnected: () => void;
    private readonly onEvents: (events: ExpandedEvent[]) => void;
    private readonly onState: () => void;
    private readonly onFatalDisconnect: (code: number) => void;
    private readonly domainState: DomainState<FancyTransaction>;
    private readonly loginPromise: Promise<void>;
    private readonly loginResolve: () => void;
    private readonly loginReject: (reason?: RejectionReason) => void;
    private receiveQueue: Promise<void> = Promise.resolve();
    private awaitingAck = false;

    constructor(
        session: SessionId,
        onDesync: () => void,
        onEvents: (events: ExpandedEvent[]) => void,
        onState: () => void,
        onFatalDisconnect: (code: number) => void,
        onConnected: () => void,
        onDisconnected: () => void,
        domainState: DomainState<FancyTransaction>,
    ) {
        this.session = session;
        this.onConnected = onConnected;
        this.onDisconnected = onDisconnected;
        this.onDesync = onDesync;
        this.onEvents = onEvents;
        this.onState = onState;
        this.onFatalDisconnect = onFatalDisconnect;
        this.domainState = domainState;

        [this.loginPromise, this.loginResolve, this.loginReject] = newPromise();

        setInterval(() => {
            void this.sendNextCommand();
        }, 5_000);
    }

    public reconnectIfNeeded() {
        this.ws?.connect();
    }

    public start(): Promise<void> {
        this.ws = new WebSocketApi(
            globalThis.appEnv.WEBSOCKET_URL,
            () => {
                this.connected = true;
                return this.onOpen();
            },
            (ev: MessageEvent<string>) => this.onMessage(ev),
            () => {
                this.connected = false;
                this.onDisconnected();
            },
            (_ev: Event) => {
                this.loginReject("Connection error");
            },
            (code: number) => this.onClose(code),
        );

        return this.loginPromise;
    }

    private send(msg: WSClientToServer): void {
        if (msg.type === "WSDomainCommands") {
            // return; // NB. useful to comment this out to test stuff on the client only
            this.awaitingAck = true;
        }
        this.ws?.send(JSON.stringify(msg));
    }

    private async onOpen(): Promise<void> {
        this.send({
            type: "WSUserSuppliedSession",
            clientVersion: globalThis.appEnv.VERSION as ClientVersion,
            sessionId: this.session,
            clientState: Store.getItem("clientState"),
        });
    }

    private async onMessage(ev: MessageEvent<string>): Promise<void> {
        this.receiveQueue = this.receiveQueue.then(async () => {
            const msg = JSON.parse(ev.data) as WSServerToClient; // TODO: should parse this properly
            switch (msg.type) {
                case "WSAwaitingCommand": {
                    this.loginResolve();
                    this.onConnected();
                    await this.sendNextCommand();
                    break;
                }

                case "WSState": {
                    await domainTx(async tx => {
                        await tx.table("entrypoints").put({id: 'root', val: msg.root});
                        await tx.table("items").bulkPut(msg.items);
                        await tx.table("geoLocations").bulkPut(msg.geoLocations);
                        await tx.table("schedule").bulkPut(msg.schedule);
                        await tx.table("config").bulkPut(Object.entries(msg.config).map(([k, v]) => ({key: k as keyof AccountConfiguration, value: v})));
                    }).then(() => Store.setItem("clientState", msg.clientState));

                    const oldSearchRoot = mutable.search.get(ROOT)
                    const newSearchRoot = {...oldSearchRoot};
                    newSearchRoot.children = msg.root.children;
                    newSearchRoot.newChildLocation = msg.root.newChildLocation;
                    mutable.search.remove(oldSearchRoot);
                    mutable.search.add(newSearchRoot);

                    mutable.search.add(...msg.items);
                    mutable.search.commit();
                    lru.clear();

                    this.onState();
                    break;
                }

                case "WSEventId": {
                    Store.setItem("clientState", {type: "LastObservedEvent", id: msg.id});
                    break;
                }

                case "WSEventForUi": {
                    await domainTx(async tx => {
                        this.onEvents([await expandEvent(tx, msg.event)]);
                    });
                    Store.setItem("clientState", {type: "LastObservedEvent", id: msg.id});
                    break;
                }

                case "WSEvent": {
                    await domainTx(async tx => {
                        const uiEvents = await applyEvents(tx, this.domainState, [[msg.event, msg.time]], postItemRemoval);
                        const expandedUiEvents = await Promise.all(uiEvents.map(([uev,]) => expandEvent(tx, uev)));
                        this.onEvents(expandedUiEvents);
                    })
                    Store.setItem("clientState", {type: "LastObservedEvent", id: msg.id} as WSClientState);
                    break;
                }

                case "WSCommandAck": {
                    const filteredQueuedCommandGroups = Store.getItem("queuedCommandGroups").filter(cg => cg.id !== msg.id);
                    Store.setItem("queuedCommandGroups", filteredQueuedCommandGroups);
                    mutable.send({type: "CommandGroupCount", count: filteredQueuedCommandGroups.length});
                    this.awaitingAck = false;
                    await this.sendNextCommand();
                    break;
                }

                case "WSDesync": {
                    for (const cmd of Store.getItem("queuedCommandGroups")) {
                        this.send(cmd as WSDomainCommands);
                    }

                    Store.removeItem("queuedCommandGroups");

                    this.ws?.close();
                    this.onDesync();

                    break;
                }

                default: {
                    const _: never = msg;
                    throw new Error(`Unexpected object: ${JSON.stringify(msg)}`);
                }
            }
        });
    }

    private async sendNextCommand(): Promise<CmdIdentifier | undefined> {
        // Check before reading from the DB, for efficiency
        if (this.awaitingAck) {
            return;
        }

        const cmd = Store.getItem("queuedCommandGroups")[0];

        if (!cmd) {
            return;
        }

        // NB. this could have changed in the meantime due to the "await"
        if (this.awaitingAck) {
            return;
        }

        this.send(cmd);
        return cmd.id;
    }

    private async onClose(code: number): Promise<void> {
        clientDebug(() => `Received close with code ${code}`);
        switch (code) {
            case ERROR_CODE_BAD_LOGIN:
                this.loginReject("Bad login");
                break;

            case ERROR_CODE_BAD_CLIENT_VERSION:
                await forceUpgradeButDontRefresh();

                mutable.send({
                    type: "Notify",
                    notifications: [{
                        level: "error",
                        title: "Your client is out of date",
                        message: "Please refresh the page to get the latest version.",
                    }],
                });

                // window.location.reload();
                break;

            default:
                // debugger;
                console.error(`code = ${code}`);
                this.onFatalDisconnect(code);
        }
    }

    queueCommand(commands: readonly Command[]): CmdIdentifier {
        const queuedCommandGroups = Store.getItem("queuedCommandGroups");
        const id = uuidv4() as CmdIdentifier;
        queuedCommandGroups.push({type: "WSDomainCommands", id: id, commands});
        Store.setItem("queuedCommandGroups", queuedCommandGroups);

        if (!this.connected) {
            mutable.send({type: "CommandGroupCount", count: queuedCommandGroups.length});
        }

        void this.sendNextCommand(); // full async
        return id;
    }
}

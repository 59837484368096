import {
    ExpandedEvent, ItemGraph,
    Model,
    Msg,
} from "./types";
import {
    Child,
    Command,
    Id,
    ROOT,
} from "../common/domain/data";
import {applyEvents, processCommand, UiEvent} from "../common/domain/logic";
import {expandEvent} from "./data-load";
import {addNotifications, postItemRemoval} from "./util";
import {db, FancyTransaction} from "./db";
import {domainState} from "./domain-state";
import {mutable} from "./mutable";
import {deepEqual} from "../common/deep-equal";
import {nowISO, parseIsoDateTime} from "../common/datetime";
import {isSomething} from "../common/util";
import {UrlPath} from "../common/urlpath";
import {toIds} from "./view/util";

export function update(model: Model, msg: Msg): Model {
    switch (msg.type) {
        case "Notify":
            return addNotifications(model, msg.notifications);

        case "DismissNotification":
            let filtered = false;
            return {...model, notifications: model.notifications.flatMap((n) => {
                    if (filtered) return [n];
                    if (deepEqual(n, msg.notification)) {
                        filtered = true;
                        return [];
                    }
                    return [n];
                })};

        case "WindowSizeChanged":
            return {
                ...model,
                windowSize: msg.size,
            };

        case "PathChange":
            return {...model, path: msg.path, graph: {...model.graph, breadcrumbs: loadBreadcrumbs(msg.path), focus: loadItemGraph(msg.path, model.graph.childDepth)}};

        case "ProtocolLoggedIn":
            return {...model, session: {type: "LoggedIn", session: msg.session}, connectionStatus: "connected"};

        case "ProtocolBadSession":
            return {...model, session: {type: "BadSessionError"}};

        case "Disconnected":
            return {...model, connectionStatus: "disconnected-retrying"};

        case "CommandGroupCount":
            return {...model, commandGroupCount: msg.count};

        case "StateLoaded":
            return {
                ...model,
                graph: {
                    ...model.graph,
                    breadcrumbs: loadBreadcrumbs(model.path),
                    focus: loadItemGraph(model.path, model.graph.childDepth),
                }
            };

        case "Events":
            let m = {...model};
            for (const event of msg.events) {
                m = updateFromEvent(m, event);
            }
            return m;

        case "SetGraphModal":
            return {
                ...model,
                graph: {
                    ...model.graph,
                    modal: msg.modal,
                },
            };

        case "LoadGeo":
            return {
                ...model,
                geoLocation: {
                    fallbackPath: msg.fallbackPath,
                    locations: {
                        editing: null,
                        named: msg.locations
                    }
                }
            };

        case "EditNamedLocation":
            return {
                ...model,
                geoLocation: model.geoLocation === null ? null : {...model.geoLocation, locations: {...model.geoLocation.locations, editing: msg.location}},
            };

        case "LoadSchedule":
            return {
                ...model,
                scheduling: {
                    ...model.scheduling,
                    lastScheduleExecution: msg.lastScheduleExecution,
                    schedule: msg.schedule,
                },
            };

        case "EditScheduled":
            return {
                ...model,
                scheduling: {
                    ...model.scheduling,
                    editing: msg.id,
                },
            };

        case "SetChildDepth":
            return {
                ...model,
                graph: {
                    ...model.graph,
                    childDepth: msg.depth,
                    focus: loadItemGraph(model.path, msg.depth),
                },
            };

        default: {
            const _: never = msg;
            throw new Error(`Unexpected message: ${JSON.stringify(msg)}`);
        }
    }

    return model;
}

export function loadBreadcrumbs(path: UrlPath): readonly ItemGraph[] {
    if (path.type !== "Graph") return [];
    return path.breadcrumbs.map(b => mutable.search.get(b)).filter(isSomething).flatMap(b => b.id === ROOT ? [] : [b]);
}

export function loadItemGraph(path: UrlPath, depth: number): ItemGraph {
    if (path.type !== "Graph") return expandItemGraph(mutable.search.get(ROOT), 0);
    const focusId = path.breadcrumbs[path.breadcrumbs.length - 1];2
    if (!focusId) return expandItemGraph(mutable.search.get(ROOT), depth);
    const focus = mutable.search.get(focusId);
    if (!focus) return expandItemGraph(mutable.search.get(ROOT), depth);
    return expandItemGraph(focus, depth);
}

function expandItemGraph(item: ItemGraph, depth = 0): ItemGraph {
    if (depth === 0) return item;

    return {
        ...item,
        children: toIds(item.children).flatMap(c => {
            const child = mutable.search.get(c);
            if (!child) return [];
            return [expandItemGraph(child, depth - 1) as Child<ItemGraph<Id>>];
        }),
    };
}

function updateFromEvent(model: Model, ev: ExpandedEvent): Model {
    switch (ev.type) {
        case "ChildAdded":
        case "ChildAttached":
        case "ChildDetached":
        case "LayerRemoved":
        case "AutomatedChildDetached":
        case "ChildMassDetached":
        case "DoneEdited":
        case "AutomatedDoneEdited":
        case "TitleEdited":
        case "DetailEdited":
        case "CompletableEdited":
        case "ChildrenReordered":
        case "AutomatedCountAggregation":
        case "ChildrenReorderedRelative":
        case "NewChildLocationUpdated": {
            return {
                ...model,
                graph: {
                    ...model.graph,
                    breadcrumbs: loadBreadcrumbs(model.path),
                    focus: loadItemGraph(model.path, model.graph.childDepth),
                }};
        }

        case "FallbackGeoLocationPathSet": {
            return {
                ...model,
                geoLocation: model.geoLocation === null ? null : {
                    ...model.geoLocation,
                    fallbackPath: ev.path,
                },
            };
        }

        case "GeoLocationAdded": {
            const updatedLocations = {...model.geoLocation?.locations.named};
            updatedLocations[ev.geoLocation.name] = ev.geoLocation;
            return {
                ...model,
                geoLocation: model.geoLocation === null ? null : {
                    ...model.geoLocation,
                    locations: {
                        ...model.geoLocation.locations,
                        named: updatedLocations,
                    },
                },
            };
        }

        case "GeoLocationEdited": {
            const updatedLocations = {...model.geoLocation?.locations.named};
            if (ev.name !== ev.geoLocation.name) {
                delete updatedLocations[ev.name];
            }
            updatedLocations[ev.geoLocation.name] = ev.geoLocation;
            return {
                ...model,
                geoLocation: model.geoLocation === null ? null : {
                    ...model.geoLocation,
                    locations: {
                        ...model.geoLocation.locations,
                        named: updatedLocations,
                    },
                },
            };
        }

        case "GeoLocationRemoved": {
            const updatedLocations = {...model.geoLocation?.locations.named};
            delete updatedLocations[ev.name];
            return {
                ...model,
                geoLocation: model.geoLocation === null ? null : {
                    ...model.geoLocation,
                    locations: {
                        ...model.geoLocation.locations,
                        named: updatedLocations,
                    },
                },
            };
        }

        case "ScheduledAdded": {
            const updatedSchedule = {...model.scheduling.schedule};
            updatedSchedule[ev.scheduled.id] = ev.scheduled;

            return {
                ...model,
                scheduling: {
                    ...model.scheduling,
                    schedule: updatedSchedule,
                },
            }
        }

        case "ScheduledEdited": {
            const updatedSchedule = {...model.scheduling.schedule};
            updatedSchedule[ev.scheduled.id] = ev.scheduled;

            return {
                ...model,
                scheduling: {
                    ...model.scheduling,
                    schedule: updatedSchedule,
                },
            }
        }

        case "ScheduledRemoved": {
            const updatedSchedule = {...model.scheduling.schedule};
            delete updatedSchedule[ev.id];

            return {
                ...model,
                scheduling: {
                    ...model.scheduling,
                    schedule: updatedSchedule,
                },
            }
        }

        case "ScheduleExecuted": {
            return {
                ...model,
                scheduling: {
                    ...model.scheduling,
                    lastScheduleExecution: parseIsoDateTime(ev.date)
                }
            };
        }

        default: {
            const _: never = ev;
            throw new Error(`Unexpected event: ${JSON.stringify(ev)}`);
        }
    }

    return model;
}

export type DomainTransaction = FancyTransaction & { readonly __tag_DomainTransaction: unique symbol };
export async function domainTx<T>(scope: (tx: DomainTransaction) => Promise<T>): Promise<T> {
    return (await db).transaction("readwrite", ["entrypoints", "items", "geoLocations", "schedule", "config"], scope as unknown as (tx: FancyTransaction) => Promise<T>);
}

export type ReadonlyDomainTransaction = FancyTransaction & { readonly __tag_ReadonlyDomainTransaction: unique symbol };
export async function domainTxReadonly<T>(scope: (tx: ReadonlyDomainTransaction) => Promise<T>): Promise<T> {
    return (await db).transaction("readonly", ["entrypoints", "items", "geoLocations", "schedule", "config"], scope as unknown as (tx: FancyTransaction) => Promise<T>);
}

export async function applyCommands(tx: FancyTransaction, commands: readonly Command[]): Promise<readonly UiEvent<Id, Id>[]> {
    const utcDateTime = nowISO();
    const events = [];
    for (const command of commands) {
        const cmdEvents = await processCommand(tx, domainState, command);
        events.push(...(await applyEvents(tx, domainState, cmdEvents.map(ev => [ev, utcDateTime]), postItemRemoval)).map(([uev,]) => uev));
    }
    return events;
}

export async function sendCommands(commands: readonly Command[], events: readonly UiEvent<Id, Id>[]): Promise<void> {
    if (mutable.wsConnection === undefined) {
        throw new Error("Unexpected state: connection not initialised");
    }

    mutable.wsConnection.queueCommand(commands);
    mutable.send({
        type: "Events",
        source: "SrcLocal",
        events: await db.then(db => db.transaction("readonly", ["items"], tx => Promise.all(events.map(ev => expandEvent(tx, ev)))))
    });
}

export async function handleCommands(tx: DomainTransaction, commands: readonly Command[]): Promise<void> {
    const events = await applyCommands(tx, commands);
    await sendCommands(commands, events);
}

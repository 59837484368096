// Emoticons -> Emoji mapping.
//
// (!) Some patterns skipped, to avoid collisions
// without increase matcher complicity. Than can change in future.
//
// Places to look for more emoticons info:
//
// - http://en.wikipedia.org/wiki/List_of_emoticons#Western
// - https://github.com/wooorm/emoticon/blob/master/Support.md
// - http://factoryjoe.com/projects/emoticons/
//

/* eslint-disable key-spacing */

export default {
  angry:            ['>:(', '>:-('],
  blush:            [':")', ':-")'],
  broken_heart:     ['</3', '<\\3'],
  // :\ and :-\ not used because of conflict with markdown escaping
  confused:         [':/', ':-/'], // twemoji shows question
  cry:              [":'(", ":'-(", ':,(', ':,-('],
  frowning:         [':(', ':-('],
  heart:            ['<3'],
  imp:              [']:(', ']:-('],
  innocent:         ['o:)', 'O:)', 'o:-)', 'O:-)', '0:)', '0:-)'],
  joy:              [":')", ":'-)", ':,)', ':,-)', ":'D", ":'-D", ':,D', ':,-D'],
  kissing:          [':*', ':-*'],
  laughing:         ['x-)', 'X-)'],
  neutral_face:     [':|', ':-|'],
  open_mouth:       [':o', ':-o', ':O', ':-O'],
  rage:             [':@', ':-@'],
  smile:            [':D', ':-D'],
  smiley:           [':)', ':-)'],
  smiling_imp:      [']:)', ']:-)'],
  sob:              [":,'(", ":,'-(", ';(', ';-('],
  stuck_out_tongue: [':P', ':-P'],
  sunglasses:       ['8-)', 'B-)'],
  sweat:            [',:(', ',:-('],
  sweat_smile:      [',:)', ',:-)'],
  unamused:         [':s', ':-S', ':z', ':-Z', ':$', ':-$'],
  wink:             [';)', ';-)']
}

import {ReactElement} from "react";
import React from "react";
import markdownit from 'markdown-it'
import hljs from 'highlight.js' // https://highlightjs.org
import {full as markdownitEmoji} from "markdown-it-emoji";

const markdownRenderer: markdownit = markdownit({
    linkify: true,
    typographer: true,
    breaks: true,
    highlight: function (str, lang) {
        if (lang && hljs.getLanguage(lang)) {
            try {
                return '<pre><code class="hljs">' +
                    hljs.highlight(str, { language: lang, ignoreIllegals: true }).value +
                    '</code></pre>';
            } catch (__) {}
        }

        return '<pre><code class="hljs">' + markdownRenderer.utils.escapeHtml(str) + '</code></pre>';
    },
})
    .use(markdownitEmoji)

function render(md: string): ReactElement | ReactElement[] {
    try {
        return <div dangerouslySetInnerHTML={{__html: markdownRenderer.render(md) }} />;
    } catch (e) {
        console.error(e);
        return <pre>{md}</pre>;
    }
}

export function Markdown({md}: {md: string}): ReactElement {
    return <div className={"markdown"}>{render(md)}</div>;
}
